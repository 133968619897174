const navigatorErrorMessage =
  'Could not find `userAgent` or `userAgentData` window.navigator properties to set `os`, `browser` and `version`';
const removeExcessMozillaAndVersion = /^mozilla\/\d\.\d\W/;
const browserPattern = /(\w+)\/(\d+\.\d+(?:\.\d+)?(?:\.\d+)?)/g;
const engineAndVersionPattern = /^(ver|cri|gec)/;
const brandList = ['chrome', 'opera', 'safari', 'edge', 'firefox'];
const unknown = 'Unknown';
const empty = '';
const { isArray } = Array;

// Define the type for mobile OS keys
type MobileOS = 'iphone' | 'ipad' | 'android';

// Define the type for desktop OS keys
type DesktopOS = 'windows' | 'mac' | 'linux';

const mobiles: Record<MobileOS, RegExp> = {
  iphone: /iphone/,
  ipad: /ipad|macintosh/,
  android: /android/,
};

const desktops: Record<DesktopOS, RegExp> = {
  windows: /win/,
  mac: /macintosh/,
  linux: /linux/,
};

const detectPlatform = () => {
  if (typeof window === 'undefined') return;

  const userAgentData = window.navigator.userAgentData;
  const userAgent = window.navigator.userAgent;

  if (userAgent) {
    const ua = userAgent.toLowerCase().replace(removeExcessMozillaAndVersion, empty);

    // Determine the operating system.
    const mobileOS = Object.keys(mobiles).find(
      (os) => mobiles[os as MobileOS].test(ua) && window.navigator.maxTouchPoints >= 1,
    );
    const desktopOS = Object.keys(desktops).find((os) => desktops[os as DesktopOS].test(ua));
    const os = mobileOS || desktopOS;

    // Extract browser and version information.
    const browserTest = ua.match(browserPattern);
    const versionRegex = /version\/(\d+(\.\d+)*)/;
    const safariVersion = ua.match(versionRegex);
    const saVesion = isArray(safariVersion) ? safariVersion[1] : null;
    const browserOffset =
      browserTest && (browserTest.length > 2 && !engineAndVersionPattern.test(browserTest[1]) ? 1 : 0);
    const browserResult = browserTest && browserTest[browserTest.length - 1 - (browserOffset || 0)].split('/');
    const browser = browserResult && browserResult[0];
    const version = saVesion ? saVesion : browserResult && browserResult[1];

    return { os, browser, version };
  } else if (userAgentData) {
    const os = userAgentData.platform.toLowerCase();
    let platformData;

    // Extract platform brand and version information.
    for (const agentBrand of userAgentData.brands) {
      const agentBrandEntry = agentBrand.brand.toLowerCase();
      const foundBrand = brandList.find((brand) => {
        if (agentBrandEntry.includes(brand)) return brand;

        return null;
      });
      if (foundBrand) {
        platformData = { browser: foundBrand, version: agentBrand.version };
        break;
      }
    }
    const brandVersionData = platformData || {
      browser: unknown,
      version: unknown,
    };
    return { os, ...brandVersionData };
  } else {
    // Log error message if there's a problem.
    console.error(navigatorErrorMessage);

    return {
      // Ignore the VSCode strikethough. Disable linting line if necessary. This is just a fallback
      os: navigator.platform || unknown,
      browser: unknown,
      version: unknown,
    };
  }
};

export default detectPlatform;

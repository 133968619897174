import { type ReactNode } from 'react';

import { useEppo } from '../eppo/useEppo';

interface IAutoAcceptFavouritesExperiment {
  children: ReactNode;
}

export const AutoAcceptFavouritesExperiment = ({ children }: IAutoAcceptFavouritesExperiment) => {
  const variation = useEppo({ flagKey: 'auto-accept-favourites', defaultValue: false });

  return <>{variation && children}</>;
};

import { type ChangeEvent, type KeyboardEvent, type ReactElement, type Ref } from 'react';

import { TextInput, type TextInputProps } from '@/components/ui';

interface ISearchLocationFormProps extends TextInputProps {
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  inputValue: string | undefined;
  showSuggestions: boolean;
  handleOnKeyDown: ((e: KeyboardEvent<HTMLInputElement>) => void) | undefined;
  children: ReactElement;
  dataTestId: string;
  rightSection: ReactElement;
  inputRef: Ref<HTMLInputElement>;
}

export const SearchInput = ({
  label,
  onChange,
  onFocus,
  inputValue,
  showSuggestions,
  handleOnKeyDown,
  children,
  dataTestId,
  rightSection,
  inputRef,
}: ISearchLocationFormProps) => (
  <div className="relative mb-8">
    <TextInput
      ref={inputRef}
      label={label}
      data-testid={dataTestId}
      id="search"
      type="text"
      name="searchField"
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={handleOnKeyDown}
      value={inputValue}
      rightSection={rightSection}
      aria-autocomplete="list"
      aria-controls="suggestions-googlemaps-location-list"
    />
    {showSuggestions && inputValue && { ...children }}
  </div>
);

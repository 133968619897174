import { useMemo } from 'react';

import { IconAlertCircle, IconCircleCheck } from '@tabler/icons-react';

import { ActionIcon } from '../ActionIcon';
import { IconCloseCircle } from '../icons';

export interface ToastProps {
  id: string;
  message?: string;
  type?: ToastType;
  timeout?: number | false;
}

export type ToastType = 'warning' | 'success' | 'error' | undefined;

interface Props {
  toast: ToastProps;
  onClose: () => void;
}

const Toast = ({ toast, onClose }: Props) => {
  const icon = useMemo(() => {
    switch (toast.type) {
      case 'success':
        return <IconCircleCheck className="text-navyBlue" stroke={2} size={26} />;
      case 'warning':
        return <IconAlertCircle className="text-warning" size={26} />;
      case 'error':
        return <IconAlertCircle className="text-red-500" size={26} />;
    }
    return undefined;
  }, [toast.type]);

  return (
    <div key={toast.id} className="mb-4 w-full max-w-[1400px] rounded bg-gradient-blue-green-x p-px drop-shadow">
      <div className="flex w-full min-w-[350px] max-w-[1400px] items-center justify-between gap-6 rounded-[0.20rem] bg-white p-5 md:gap-12">
        <div className="flex items-center gap-6">
          <div>{icon}</div>
          <p className={'m-0 text-black'}>{toast.message}</p>
        </div>
        <ActionIcon
          styleVariant="transparent"
          onClick={onClose}
          title=""
          aria-label="Close"
          className="text-inherit"
          data-testid="closeToast"
          icon={<IconCloseCircle />}
        />
      </div>
    </div>
  );
};

export default Toast;

import { useEffect } from 'react';

import Toast from './Toast';
import { useToastStateContext } from './ToastContext';
import { useDeleteToast } from './useDeleteToast';

interface Props {
  autoDelete: boolean;
  dismissTime?: number;
}

// eslint-disable-next-line no-undef
const toastTimers = new Map<string, NodeJS.Timeout>();

const ToastContainer = ({ autoDelete, dismissTime = 5000 }: Props) => {
  const toasts = useToastStateContext();
  const deleteToast = useDeleteToast();

  useEffect(() => {
    if (autoDelete) {
      for (const toast of toasts) {
        if (toast.timeout !== false && !toastTimers.has(toast.id)) {
          const timer = setTimeout(() => {
            deleteToast(toast.id);
            toastTimers.delete(toast.id);
          }, toast.timeout ?? dismissTime);
          toastTimers.set(toast.id, timer);
        }
      }
    }
  }, [autoDelete, deleteToast, dismissTime, toasts]);

  if (toasts.length < 1) return null;

  return (
    <div data-compenent="ToastContainer" className="fixed left-1/2 top-8 z-[1000] -translate-x-1/2">
      {toasts.map((toast, i) => (
        <Toast key={i} toast={toast} onClose={() => deleteToast(toast.id)} />
      ))}
    </div>
  );
};

export default ToastContainer;

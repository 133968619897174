import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  let width = undefined;
  let height = undefined;

  if (typeof window !== 'undefined') {
    const { innerWidth, innerHeight } = window;

    width = innerWidth;
    height = innerHeight;
  }

  return {
    width: typeof width !== 'undefined' ? width : 0,
    height: typeof height !== 'undefined' ? height : 0,
  };
};

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    return undefined;
  }, []);

  return windowDimensions;
}

/**
 * @deprecated use useBreakpoints instead
 */
export default function useIsMobileOrTablet() {
  const { width } = useWindowDimensions();

  return width && width <= 768;
}

export const useBreakPoints = () => {
  const { width } = useWindowDimensions();

  return {
    mobile: width && width <= 768,
    tablet: width && width <= 1024,
    desktop: width && width > 1024,
  };
};

import { useMemo } from 'react';

import { getInstance } from '@eppo/js-client-sdk';

import { useAuth } from '../auth/AuthContext';

type Attributes = Record<string, string | number | boolean>;

interface IUseEppo {
  flagKey: string;
  subjectAttributes?: Attributes;
  defaultValue: string | number | boolean;
}

export const useEppo = ({ flagKey, subjectAttributes, defaultValue }: IUseEppo) => {
  const { user } = useAuth();
  const clientId = user?.clientId as string;

  const variation = useMemo(() => {
    const eppoInstance = getInstance();

    if (typeof defaultValue === 'boolean')
      return eppoInstance.getBooleanAssignment(flagKey, clientId, subjectAttributes ?? {}, defaultValue);
    if (typeof defaultValue === 'string')
      return eppoInstance.getStringAssignment(flagKey, clientId, subjectAttributes ?? {}, defaultValue);
    if (typeof defaultValue === 'number')
      return eppoInstance.getIntegerAssignment(flagKey, clientId, subjectAttributes ?? {}, defaultValue);

    throw new Error(`Unsupported assignment type: ${typeof defaultValue}`);
  }, [clientId, flagKey, subjectAttributes, defaultValue]);

  return variation;
};

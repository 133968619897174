import { type FieldValues, type Path, type UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

import { Label } from './Label';

interface PhoneInputSelectProps<T extends FieldValues> {
  setValue: UseFormSetValue<T>;
  setPhoneNumberExt: React.Dispatch<
    React.SetStateAction<{
      number?: string;
      ext?: string;
      countryCode?: string;
    } | null>
  >;
  phoneNumberExt: {
    number?: string;
    ext?: string;
    countryCode?: string;
  } | null;
  isValid?: boolean;
  phoneNumberField: Path<T>;
  phoneNumberExtField: Path<T>;
  phoneNumberError: string | undefined;
  phoneNumberExtensionError?: string | undefined;
  className?: string;
}

export const PhoneInputSelect = <T extends FieldValues>({
  setValue,
  setPhoneNumberExt,
  phoneNumberExt,
  isValid = true,
  phoneNumberError,
  phoneNumberExtensionError,
  phoneNumberField,
  phoneNumberExtField,
  className,
}: PhoneInputSelectProps<T>) => {
  const { t } = useTranslation();

  return (
    <div className={`${className}`}>
      <Label htmlFor="phoneNumber" className="mb-2">
        {t('globals.v2.phone')}
      </Label>
      <PhoneInput
        country={phoneNumberExt?.countryCode?.toLowerCase()}
        onlyCountries={['nl', 'be', 'gb', 'fr']}
        autoFormat={false}
        isValid={isValid && !phoneNumberExtensionError && !phoneNumberError}
        value={`${phoneNumberExt?.ext}${phoneNumberExt?.number}`}
        onChange={(value, country) => {
          if (value && typeof country === 'object' && country !== null && 'dialCode' in country) {
            const numberWithoutDialCode = value.replace(country.dialCode, '');
            const dialCode = `+${country.dialCode}`;

            setValue(phoneNumberField, numberWithoutDialCode as unknown as T[keyof T], { shouldDirty: true });
            setValue(phoneNumberExtField, dialCode as unknown as T[keyof T], { shouldDirty: true });
            setPhoneNumberExt({ number: numberWithoutDialCode, ext: dialCode });
          }
        }}
      />
      <span className="text-xs text-red-500">{phoneNumberExtensionError || phoneNumberError}</span>
    </div>
  );
};

export enum Region {
  NETHERLANDS = 'nl',
  BELGIUM = 'be',
  UNITED_KINGDOM = 'en',
  FRANCE = 'fr',
}

export enum JOB_STATUS {
  OPEN = 'open',
  READY_FOR_EXECUTION = 'ready_for_execution',
  IN_PROGRESS = 'in_progress',
  PAYOUT = 'payout',
  ARCHIVED = 'archived',
}

export enum JOB_SLUG {
  OPEN = 'open',
  READY_FOR_EXECUTION = 'ready-for-execution',
  IN_PROGRESS = 'payout',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PAYOUT = 'payout',
  ARCHIVED = 'archived',
}

export enum ClaimStatus {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  ACCEPTED = 'accepted',
  DISPUTED = 'disputed',
  REJECTED = 'rejected',
}

export enum ERRORS_IDENTIFIERS {
  CLIENT_NOT_VERIFIED = 'client:user_email_not_verified',
  CLIENT_USER_PASSWORD_MISMATCH = 'client:user_password_mismatch',
}

export enum MantineDatesProviderLocale {
  NETHERLANDS = 'nl',
  BELGIUM = 'nl-BE',
  ENGLISH = 'en',
  FRANCE = 'fr',
}

export enum SUPPORTED_SUCCESS_STATUS_CODES {
  SUCCESS = 200,
  CREATED = 201,
  UPDATED = 205,
}

export const SETTINGS_TABS = {
  WORKSPACES: 'workspaces',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  ADMINISTRATORS: 'administrators',
  LOCATIONS: 'locations',
  REFERENCE_NUMBERS: 'referenceNumbers',
  INVOICES: 'invoices',
};

export const WORKSPACE_TABS = {
  PROFILE: 'profile',
  SETTINGS: 'settings',
  USERS: 'users',
  LOCATIONS: 'locations',
  REFERENCE_NUMBERS: 'referenceNumbers',
  INVOICES: 'invoices',
};

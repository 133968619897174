export enum ACTION_ICON_STYLE_VARIANT {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
  SMALL = 'small',
  ROUND = 'round',
}

export enum THEME_ICON_STYLE_VARIANT {
  PINK_WITH_BACKGROUND = 'pinkWithBackground',
}

export enum COLOR {
  PRIMARY = 'primary',
  BLACK = 'black',
  NAVY_BLUE = 'navyBlue',
  LIGHT_BLUE = 'lightBlue',
  GREEN = 'green',
  GREEN_LIGHT = 'greenLight',
  PINK = 'pink',
  PINK_LIGHT = 'pinkLight',
  ORANGE = 'orange',
  ORANGE_LIGHT = 'orangeLight',
  ACCENT_BLUE = 'accentBlue',
  ACCENT_BLUE_LIGHT = 'accentBlueLight',
  YELLOW = 'yellow',
  CHECK = 'check',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING_LIGHT = 'warningLight',
  ULTRA_LIGHT_BLUE = 'ultraLightBlue',
  ULTRA_LIGHT_GRAY = 'ultraLightgray',
  TURQUOISE = 'turquoise',
  RED = 'red',
  GRAY = 'gray',
  WHITE = 'white',
  PURPLE = 'purple',
  PURPLE_LIGHT = 'purpleLight',
  NAVY_BLUE_SHADES = 'navyBlueShades',
}

export enum REGION {
  NETHERLANDS = 'nl',
  BELGIUM = 'nl-BE',
  UNITED_KINGDOM = 'gb',
}

export enum TOOLTIP_COLOR {
  DEFAULT = 'default',
  WHITE = 'white',
}

import React, { forwardRef, type ReactNode, type TextareaHTMLAttributes } from 'react';

import { cn } from './utils/cn';

export interface ITextarea extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'className' | 'rows'> {
  label?: ReactNode;
  error?: string;
  className?: string;
  rows?: number;
}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextarea>(
  ({ className, label, error, rows = 8, ...props }, ref) => (
    <div className="relative grid gap-2">
      {label && <label className="flex items-center gap-1 text-xs font-medium text-black">{label}</label>}
      <textarea
        data-component="TextArea"
        rows={rows}
        ref={ref}
        className={cn(
          'border bg-ultraLightgray rounded p-3 border-gray-200 text-sm text-black placeholder:text-gray-800 disabled:border-gray-300 disabled:bg-gray-100 w-full focus:outline-none focus:ring-0',
          {
            'border-red-500 text-red-500 selection:bg-red-200 focus:border-red-500 focus:ring-red-500 focus:placeholder:text-red-500 active:border-red-500 active:ring-red-500':
              !!error,
          },
          className,
        )}
        {...props}
      />
      {error && <small className="text-xs text-red-500">{error}</small>}
    </div>
  ),
);

import React, { type ButtonHTMLAttributes, forwardRef, type MouseEvent } from 'react';

import clsx from 'clsx';

import { type IEventData, trackEvent } from '@/utils/events/trackEvent';

import { IconLoading } from './icons/IconLoading';
import { cn } from './utils/cn';
import formatIcon from './utils/formatIcon';

const defaultButtonStyling =
  'justify-center group relative flex items-center gap-2 cursor-pointer border rounded py-2 px-5 text-sx md:text-sm md:font-medium font-medium hover:outline-none focus:outline-none disabled:cursor-not-allowed';

export const buttonVariants = {
  primary: clsx(
    defaultButtonStyling,
    'border-transparent bg-primary text-black hover:bg-turquoise-700 hover:fill-white disabled:bg-turquoise-100 disabled:text-gray-800 disabled:hover:bg-turquoise-100',
  ),
  secondary: clsx(
    defaultButtonStyling,
    'border-solid border-gray-200 bg-white text-black hover:border-gray-800 hover:bg-gray-50 hover:fill-white disabled:text-gray-800 disabled:hover:border-gray-200 disabled:hover:bg-white',
  ),
  danger: clsx(
    defaultButtonStyling,
    'border-transparent bg-red-500 text-white hover:bg-red-700 disabled:bg-red-100 disabled:hover:bg-red-100',
  ),
  link: clsx(
    defaultButtonStyling,
    'border-transparent bg-transparent text-black hover:bg-gray-50 disabled:bg-transparent disabled:text-gray-800 disabled:hover:bg-transparent',
  ),
  outline: clsx(
    defaultButtonStyling,
    'border-solid border-gray-200 bg-transparent text-white hover:border-gray-800 hover:bg-[rgba(255,255,255,0.1)] disabled:text-gray-800 disabled:hover:border-gray-200 disabled:hover:bg-white',
  ),
  search: clsx(defaultButtonStyling, 'absolute right-0 top-0 h-full w-[50px] border-0 bg-transparent bg-no-repeat'),
  icon__primary: clsx(defaultButtonStyling, '!flex items-center justify-center'),
  icon__danger: clsx(defaultButtonStyling, '!flex items-center justify-center'),
} as const;

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof buttonVariants;
  ariaLabel?: string;
  isLoading?: boolean;
  block?: boolean;
  dataTestId?: string;
  icon?: React.ReactNode;
  iconRight?: boolean;
  eventData?: IEventData;
}

export const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      type = 'button',
      variant = 'primary',
      block = false,
      isLoading = false,
      icon: iconFromProps = null,
      iconRight = false,
      eventData,
      onClick,
      className,
      children,
      dataTestId,
      ...props
    }: IButton,
    ref,
  ) => {
    const icon = isLoading ? <IconLoading className="mr-2 animate-spin-slow stroke-current" /> : iconFromProps;

    const formattedIcon = formatIcon(icon, {
      className: clsx('size-[1.125rem]', {
        '-mr-2': iconRight,
        '-ml-2': !iconRight,
      }),
    });

    const handleOnClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (eventData) trackEvent('Button', eventData);

      onClick && onClick(event);
    };

    return (
      <button
        type={type}
        data-testid={dataTestId}
        className={cn(buttonVariants[variant], className, block && 'w-full')}
        onClick={(event) => handleOnClick(event)}
        ref={ref}
        {...props}
      >
        {!iconRight && formattedIcon}
        {children}
        {iconRight && formattedIcon}
      </button>
    );
  },
);

Button.displayName = 'Button';

import * as React from 'react';
import { type SVGProps } from 'react';
export const IconJobs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    width="1em"
    height="1em"
    viewBox="0 0 25 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.10559 14.44H23.9344" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21.16 1H3.88C2.2864 1 1 2.5552 1 4.4752V25.3648C1 27.2848 2.2864 28.84 3.88 28.84H21.16C22.7536 28.84 24.04 27.2848 24.04 25.3648V4.4752C24.04 2.5552 22.7536 1 21.16 1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.95996 6.76001V7.72001C7.95996 8.24801 8.39196 8.68001 8.91996 8.68001H16.12C16.648 8.68001 17.08 8.24801 17.08 7.72001V6.76001"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M7.95996 21.16V22.12C7.95996 22.648 8.39196 23.08 8.91996 23.08H16.12C16.648 23.08 17.08 22.648 17.08 22.12V21.16"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

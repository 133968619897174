import React, { forwardRef, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, {
  components,
  type ControlProps,
  type DropdownIndicatorProps,
  type GroupBase,
  type GroupHeadingProps,
  type NoticeProps,
  type Props,
  type SingleValueProps,
  type StylesConfig,
  type ThemeConfig,
} from 'react-select';

import { IconSelector, IconX } from '@tabler/icons-react';
import clsx from 'clsx';

import { Label } from './Label';

// Define language flags outside the component
const LANGUAGE_FLAGS = [
  { value: 'nl-NL', altValue: 'nl_NL', label: 'nl' }, // NL flag
  { value: 'nl-BE', altValue: 'nl_BE', label: 'be' }, // BE flag
  { value: 'en-GB', altValue: 'en_GB', label: 'gb' }, // GB flag
  { value: 'fr-FR', altValue: 'fr_FR', label: 'fr' }, // FR flag
];

interface CustomSelectProps<T> extends Props<T, false, GroupBase<T>> {
  small?: boolean;
  error?: string;
  icon?: ReactElement;
  showLangFlags?: boolean;
  hideErrorText?: boolean;
  fullWidth?: boolean;
  isClearable?: boolean;
  width?: string;
}

interface ISelectNewProps<T> extends CustomSelectProps<T> {
  placeholder: string;
  label?: string | ReactElement;
  className?: string;
  id: string;
}

const selectStyles: StylesConfig<unknown, false, GroupBase<unknown>> = {
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#838383',
    fontSize: '14px',
    textWrap: 'nowrap',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    color: 'black',
    marginLeft: '4px',
    marginRight: '4px',
    backgroundColor: isSelected ? '#f8f8f8' : isFocused ? '#f8f8f8' : 'transparent',
    width: 'calc(100% - 8px)',
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const selectTheme: ThemeConfig = (theme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: '#f8f8f8',
    primary: '#f8f8f8',
  },
});

export const Select = forwardRef<HTMLDivElement, ISelectNewProps<unknown>>(
  (
    {
      options,
      placeholder,
      defaultValue,
      label,
      id,
      icon,
      error,
      className,
      showLangFlags = false,
      hideErrorText = false,
      isClearable = true,
      fullWidth = true,
      width,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const DropdownIndicator = (props: DropdownIndicatorProps<unknown, false, GroupBase<unknown>>) => (
      <components.DropdownIndicator {...props}>
        {props.hasValue && isClearable ? (
          <IconX className="cursor-pointer" size={16} color="black" onClick={() => props.clearValue()} />
        ) : (
          <IconSelector size={16} color="black" />
        )}
      </components.DropdownIndicator>
    );

    const GroupHeading: React.FC<GroupHeadingProps<unknown, false, GroupBase<unknown>>> = ({ children }) => (
      <div className="flex items-stretch px-4 py-2 text-[13px] font-normal capitalize text-[#838383]">
        <span className="flex-nowrap whitespace-nowrap">{children}</span>
        <span className="ml-2 flex w-full flex-1 items-center justify-center">
          <span className="h-px w-full bg-[#838383]" />
        </span>
      </div>
    );

    const LanguageControl: React.FC<ControlProps<unknown, false>> = ({ children, ...props }) => {
      const getSelectedFlag = () => {
        const selectValue = props.selectProps.value;
        const findFlag = (value: string) =>
          LANGUAGE_FLAGS.find((e) => e.value === value || e.altValue === value)?.label || '';

        if (typeof selectValue === 'string') return findFlag(selectValue);
        if (typeof selectValue === 'object' && selectValue !== null && 'value' in selectValue)
          return findFlag(selectValue.value as string);

        return null;
      };

      const selectedFlag = getSelectedFlag();

      return (
        <components.Control {...props}>
          {selectedFlag && (
            <div className="flex h-[39px] items-center border-r p-3">
              <span className={`${selectedFlag} select-flag py-2 pl-3 pr-0`} />
            </div>
          )}
          {children}
        </components.Control>
      );
    };

    const NoOptionsMessage = (props: NoticeProps<unknown, false, GroupBase<unknown>>) => (
      <components.NoOptionsMessage {...props}>{t('globals.noOptions')}</components.NoOptionsMessage>
    );

    const SingleValue: React.FC<SingleValueProps<unknown, false>> = ({ children, ...props }) => (
      <components.SingleValue {...props}>
        <span className="flex items-center">
          {icon && icon}
          <span className="mx-2">{children}</span>
        </span>
      </components.SingleValue>
    );

    const formatGroupLabel = (data: GroupBase<unknown>) => (
      <div className="flex items-center">
        <span className="normal-case">{data.label}</span>
      </div>
    );

    return (
      <div ref={ref} className={clsx('m-0 grid gap-2 p-0', width ? width : fullWidth && 'w-full', className)}>
        {label && <Label className="leading-4">{label}</Label>}
        <ReactSelect<unknown, false, GroupBase<unknown>>
          key={id}
          data-component="select"
          options={options}
          menuPlacement="auto"
          defaultValue={defaultValue}
          placeholder={placeholder}
          isClearable={false}
          menuPortalTarget={document?.body}
          styles={{
            ...selectStyles,
            control: (base, state) => ({
              ...base,
              '&:hover': {
                borderColor: '#A8A8A8',
              },
              height: '40px',
              width: '100%',
              backgroundColor: '#fbfbfb',
              borderColor: error ? 'red' : state.isFocused ? '#10d1bb' : '#cccccc',
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 99999,
            }),
          }}
          theme={selectTheme}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
            GroupHeading,
            Control: showLangFlags ? LanguageControl : components.Control,
            SingleValue,
            NoOptionsMessage,
          }}
          formatGroupLabel={formatGroupLabel}
          {...props}
        />
        {!hideErrorText && error && (
          <small className="text-xs text-red-500" role="alert">
            {error}
          </small>
        )}
      </div>
    );
  },
);

Select.displayName = 'Select';

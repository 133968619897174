// @ts-expect-error - js-cookie is not defined in the types
import cookieStorage, { type CookieAttributes } from 'js-cookie';

export function getFromCookieStorage(key: string) {
  try {
    return cookieStorage.get(key);
  } catch (error) {
    return error;
  }
}

export function writeToCookieStorage(key: string, value: string, options: CookieAttributes) {
  try {
    cookieStorage.set(key, value, options);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function removeFromCookieStorage(key: string) {
  try {
    return cookieStorage.remove(key);
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

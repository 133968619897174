import { forwardRef } from 'react';

import { cn } from './utils/cn';
import { UnstyledButton } from './UnstyledButton';

interface IHamburgerMenuButtonProps {
  setMenuState: () => void;
  menuState: boolean;
}

export const HamburgerMenuButton = forwardRef<HTMLButtonElement, IHamburgerMenuButtonProps>(
  ({ menuState, setMenuState }: IHamburgerMenuButtonProps, ref) => {
    const defaultClasses = cn('absolute h-[2px] bg-white duration-150');

    return (
      <UnstyledButton
        onClick={setMenuState}
        className="relative h-4 w-6"
        name="hamburgerMenuButton"
        data-component="hamburgerMenuButton"
        aria-label="Mobile Menu button"
        ref={ref}
      >
        <span
          className={cn(defaultClasses, 'left-0 top-0 w-6', {
            'translation top-1/2 translate-y-[-50%] rotate-45 transform duration-150 ease-in': menuState,
          })}
        />
        <span
          className={cn(defaultClasses, 'right-0 top-1/2 w-4 translate-y-[-50%]', {
            'translation opacity-0 ease-in': menuState,
          })}
        />
        <span
          className={cn(defaultClasses, 'bottom-0 left-0 w-6', {
            'translation top-1/2 translate-y-[-50%] -rotate-45 transform duration-150 ease-in': menuState,
          })}
        />
      </UnstyledButton>
    );
  },
);

HamburgerMenuButton.displayName = 'HamburgerMenuButton';

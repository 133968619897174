import { forwardRef, type InputHTMLAttributes, type ReactNode } from 'react';

import { cn } from './utils/cn';

export interface ICheckbox extends Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'size' | 'disabled'> {
  size?: 'sm' | 'md';
  mediumBoldText?: boolean;
  label?: ReactNode;
  disabled?: boolean;
  className?: string;
  indeterminate?: boolean;
  error?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  (
    { size = 'md', className, error, mediumBoldText, label, indeterminate = false, disabled = false, ...props },
    ref,
  ) => (
    <div className={cn('relative grid gap-2', className)}>
      <label className={cn('flex cursor-pointer items-start gap-3')}>
        <div className={cn('relative grid place-content-center flex-shrink-0', size === 'sm' ? 'size-4' : 'size-5')}>
          <input
            className="peer absolute
         inset-0 shrink-0 appearance-none rounded-sm border border-gray-800
        bg-white checked:border-lightBlue checked:bg-lightBlue focus:outline-none
         disabled:border-gray-300 disabled:bg-gray-100 [&:not([disabled])]:checked:border-lightBlue [&:not([disabled])]:checked:bg-lightBlue disabled:[&[checked]]:border-gray-100
      "
            type="checkbox"
            disabled={disabled}
            ref={ref}
            {...props}
          />
          {/* Checkmark SVG for checked state */}
          <svg
            className={cn(
              'pointer-events-none absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 stroke-white outline-none peer-checked:block',
              { 'stroke-gray-300': disabled },
              size === 'sm' ? 'size-3' : 'size-4',
            )}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>

          {/* Indeterminate SVG for indeterminate state */}
          <svg
            className={cn(
              'pointer-events-none absolute left-1/2 hidden -translate-x-1/2 -translate-y-1/2 stroke-black outline-none',
              { 'stroke-gray-300': disabled },
              size === 'sm' ? 'size-3 top-[60%]' : 'size-4 top-1/2',
              { block: indeterminate },
            )}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="4" y1="12" x2="20" y2="12"></line>
          </svg>
        </div>
        {label && <span className={cn('text-sm flex-grow', { 'font-medium': mediumBoldText })}>{label}</span>}
      </label>
      {error && <small className="text-xs text-red-500">{error}</small>}
    </div>
  ),
);

Checkbox.displayName = 'Checkbox';

import * as React from 'react';
import { type SVGProps } from 'react';
export const IconBanned = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M5.66669 5.66667L24.3334 24.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

import * as React from 'react';
import { type SVGProps } from 'react';
export const IconSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.5 8C20.433 8 22 6.433 22 4.5C22 2.567 20.433 1 18.5 1C16.567 1 15 2.567 15 4.5C15 6.433 16.567 8 18.5 8Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 4.5H15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 4.5H29" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8 18.5C9.933 18.5 11.5 16.933 11.5 15C11.5 13.067 9.933 11.5 8 11.5C6.067 11.5 4.5 13.067 4.5 15C4.5 16.933 6.067 18.5 8 18.5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 15H4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 15H29" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M23.75 29C25.683 29 27.25 27.433 27.25 25.5C27.25 23.567 25.683 22 23.75 22C21.817 22 20.25 23.567 20.25 25.5C20.25 27.433 21.817 29 23.75 29Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 25.5H20.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27.25 25.5H29" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

import { forwardRef, type Ref } from 'react';
import { Tooltip } from 'react-tooltip';

import clsx from 'clsx';

import { cn } from './utils/cn';
import { ACTION_ICON_STYLE_VARIANT } from './utils/enums';
import formatIcon from './utils/formatIcon';

interface ActionIconProps {
  children?: React.ReactNode;
}

export interface IActionIcon extends Omit<ActionIconProps, 'classNames'> {
  'data-testid'?: string;
  title: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  styleVariant?: 'default' | 'transparent' | 'small' | 'round';
  onClick?: () => void;
  icon: React.ReactNode;
  iconSize?: number;
  strokeWidth?: number;
  className?: string;

  /**
   * @deprecated Can be removed and replaced by iconSize
   */
  width?: string;
  /**
   * @deprecated Can be removed and replaced by iconSize
   */
  height?: string;
}

export const ActionIcon = forwardRef(
  (
    {
      title,
      disabled = false,
      styleVariant = 'default',
      icon,
      type = 'button',
      'data-testid': testId,
      iconSize = 20,
      strokeWidth = 1,
      onClick,
      className,
      ...props
    }: IActionIcon,
    ref: Ref<HTMLButtonElement & HTMLAnchorElement>,
  ) => {
    const variantClassNames = clsx({
      'active:translate-y-[1px] content-center flex items-center justify-center rounded-[4px] border border-gray-200 hover:border-black text-black child:p-10 w-[36px] h-[36px] disabled:text-gray-500':
        styleVariant === ACTION_ICON_STYLE_VARIANT.DEFAULT,
      'active:translate-y-[1px] border-none content-center flex items-center justify-center rounded-[4px] border text-black child:p-10 w-[36px] h-[36px] disabled:text-gray-500':
        styleVariant === ACTION_ICON_STYLE_VARIANT.TRANSPARENT,
      'active:translate-y-[1px] content-center flex items-center justify-center rounded-[4px] child:p-10 w-[37px] h-[37px] text-black rounded-full bg-primary hover:bg-turquoise-700 disabled:turquoise-50 disabled:text-gray-800':
        styleVariant === ACTION_ICON_STYLE_VARIANT.ROUND,
      'w-7 h-7 active:translate-y-[1px] content-center flex items-center justify-center rounded-[4px] border border-gray-200 hover:border-black text-black hover:bg-gray-50 disabled:text-gray-500':
        styleVariant === ACTION_ICON_STYLE_VARIANT.SMALL,
    });

    return (
      <>
        <button
          title={title}
          type={type}
          disabled={disabled}
          data-testid={testId}
          data-component="ActionIcon"
          className={cn(variantClassNames, className)}
          onClick={onClick}
          ref={ref}
          data-tooltip-id={title}
          data-tooltip-content={title}
          {...props}
        >
          {formatIcon(icon, { stroke: strokeWidth, size: iconSize })}
        </button>
        <Tooltip id={title} />
      </>
    );
  },
);

import { forwardRef, useEffect, useImperativeHandle } from 'react';

import {
  IconBold,
  IconClearFormatting,
  IconItalic,
  IconList,
  IconListNumbers,
  IconUnderline,
} from '@tabler/icons-react';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import { cn } from './utils/cn';

export interface IWysiwygEditorImperativeHandle {
  resetContent: (newContent: string) => void;
}
interface IWysiwygEditor {
  id: string;
  content?: string;
  placeholder: string;
  forceRefresh?: boolean;
  readOnly?: boolean;
  onChange?: (v: string) => void;
  error?: string;
  className?: string;
  formName?: string;
}

const BoldIcon = () => <IconBold size={18} stroke={1.5} />;
const ItalicIcon = () => <IconItalic size={18} stroke={1.5} />;
const UnderlineIcon = () => <IconUnderline size={18} stroke={1.5} />;
const ClearFormattingIcon = () => <IconClearFormatting size={18} stroke={1.5} />;
const ListIcon = () => <IconList size={18} stroke={1.5} />;
const ListNumbersIcon = () => <IconListNumbers size={18} stroke={1.5} />;

export const WysiwygEditor = forwardRef(
  ({ id, content, className, placeholder, onChange, readOnly, error }: IWysiwygEditor, ref) => {
    const editor = useEditor({
      extensions: [
        StarterKit,
        Underline,
        Placeholder.configure({
          placeholder: placeholder,
        }),
      ],
      editorProps: {
        attributes: {
          class:
            'min-h-[190px] md:min-h-[150px] bg-ultraLightgray text-sm p-4 prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl focus:outline-none',
        },
      },
      content: content,
      editable: !readOnly,
      onUpdate({ editor }) {
        onChange && onChange(editor.getHTML().replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, '<li>$1<$2$3>'));
      },
    });

    useEffect(() => {
      if (!editor) return;
      if (content === null) editor.commands.setContent('');
    }, [content, editor]);

    useEffect(() => {
      if (!editor) return;
      editor.setEditable(readOnly ? !readOnly : true);
    }, [readOnly, editor]);

    useImperativeHandle(
      ref,
      () => ({
        resetContent(newContent: string) {
          editor?.commands?.setContent(newContent);
        },
      }),
      [editor],
    );

    if (typeof window === 'undefined' || typeof document === 'undefined') return null;

    return (
      <div>
        <div className={cn('border border-gray-200 rounded-md', className, error && 'border-red-600')}>
          {editor && (
            <div className="flex flex-wrap gap-2 border-b border-gray-200 p-2">
              <div className="flex flex-row divide-x divide-gray-400 rounded-md border border-gray-400">
                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleBold().run()}
                  className={`${editor.isActive('bold') ? 'bg-primary/20' : 'bg-white'} flex size-[26px] items-center justify-center rounded-l-md border-gray-400 text-xs hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-500`}
                >
                  <BoldIcon />
                </button>
                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                  className={`${editor.isActive('italic') ? 'bg-primary/20' : 'bg-white'} flex size-[26px] items-center justify-center border-gray-400 text-xs hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-500`}
                >
                  <ItalicIcon />
                </button>
                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleUnderline().run()}
                  className={`${editor.isActive('underline') ? 'bg-primary/20' : 'bg-white'} flex size-[26px] items-center justify-center border-gray-400 text-xs hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-500`}
                >
                  <UnderlineIcon />
                </button>
                <button
                  type="button"
                  onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
                  className={
                    'flex size-[26px] items-center justify-center rounded-r-md border-gray-400 bg-white text-xs hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-500'
                  }
                >
                  <ClearFormattingIcon />
                </button>
              </div>
              <div className="flex flex-row divide-x divide-gray-400 rounded-md border border-gray-400">
                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleBulletList().run()}
                  className={`${editor.isActive('bulletList') ? 'bg-primary/20 ' : 'bg-white'} flex size-[26px] items-center justify-center rounded-l-md border-gray-400 text-xs hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-500`}
                >
                  <ListIcon />
                </button>
                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleOrderedList().run()}
                  className={`${editor.isActive('orderedList') ? 'bg-primary/20 text-primary' : 'bg-white'} flex size-[26px] items-center justify-center rounded-r-md border-gray-400 text-xs hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-500`}
                >
                  <ListNumbersIcon />
                </button>
              </div>
            </div>
          )}
          <EditorContent editor={editor} data-component="wysiwygEditor" data-testid={id} id={id} className={cn('rounded-[inherit] overflow-hidden')} />
        </div>
        {error && <span className="mt-1 text-xs font-normal text-red-600">{error}</span>}
      </div>
    );
  },
);

import { type HTMLAttributes } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans } from 'next-i18next';

import { TZDate } from '@date-fns/tz';
import { format, parseISO } from 'date-fns';

import { getPageRoute } from '@/utils/constants/pageRoutes';
import { localeFormatter } from '@/utils/formatters/langFormatter';
import { useGetCurrentRegionConfig } from '@/utils/regions/regionsConfig';

import { Divider } from '../ui';

import { GigsBadges } from './GigsBadges';

import { type IJob } from '@/types';

interface IGigRow extends HTMLAttributes<HTMLButtonElement> {
  job?: IJob;
}

export const GigRow = ({ job }: IGigRow) => {
  const router = useRouter();
  const region = (router?.query?.region as string) || 'nl';
  const lang = (router?.query?.lang as string) || 'nl-NL';
  const regionConfig = useGetCurrentRegionConfig();

  if (!job) return null;

  const parsedStartDate = format(parseISO(job.startsAt), 'EEEE dd MMM yyyy', {
    locale: localeFormatter(router?.query?.lang as string),
  });
  const parsedTimeStart = format(new TZDate(job.startsAt, regionConfig.localeLocation), 'H:mm');
  const parsedTimeEnd = format(new TZDate(job.endsAt, regionConfig.localeLocation), 'H:mm');
  const houseNumber = job?.address?.houseNumber && ` ${job?.address?.houseNumber}`;
  const city = job?.address?.city && ` (${job.address.city})`;
  const street = job.address.street && job.address.street;

  return (
    <li>
      <Link
        className="relative grid min-h-[138px] w-full grid-cols-1 items-center gap-2 px-5 py-4 hover:bg-ultraLightBlue hover:no-underline lg:px-7 lg:py-10 xl:grid-cols-3"
        data-testid={`gig-row-${job.id}`}
        data-component="gigRow"
        href={getPageRoute('gigDetail', region as string, lang as string).replace('{gigSlug}', job.slug)}
      >
        <div className="col-span-2 lg:col-span-2">
          <div className="flex w-full flex-col items-baseline justify-between gap-1 md:items-start">
            <h2 className="grid gap-1 text-start text-lg font-bold text-black">{job.title}</h2>
            <div className="w-full text-xs font-normal text-gray-700">
              <div className="flex w-full flex-row flex-wrap justify-start gap-x-2">
                <span className="whitespace-nowrap">{parsedStartDate}</span>
                <span>|</span>
                <span className="whitespace-nowrap">{`${parsedTimeStart} - ${parsedTimeEnd}`}</span>
                <span>|</span>
                <span className="whitespace-nowrap">
                  {street}
                  {houseNumber}
                  {city}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2.5 xl:col-span-1 xl:items-center">
          <span className="whitespace-nowrap text-xs font-medium text-black xl:flex xl:items-center xl:justify-end">
            <Trans
              i18nKey="job.numberOfContractors"
              values={{
                count: job.numberOfContractors,
                nrOfMatches: job.countMatches,
              }}
            ></Trans>
          </span>
          <GigsBadges job={job} />
        </div>
      </Link>
      <Divider />
    </li>
  );
};

import { useEffect, useState } from 'react';

export const getStorageValue = (key: string, defaultValue?: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? saved : defaultValue;
    return initial;
  }

  return defaultValue;
};

export const setStorageValue = (key: string, value: string) => {
  if (typeof window !== 'undefined' && window.localStorage) localStorage.setItem(key, value);
};

export const removeStorage = (key: string) => {
  if (typeof window !== 'undefined' && window.localStorage) localStorage.removeItem(key);
};

export const clearStorage = () => {
  if (typeof window !== 'undefined' && window.localStorage) localStorage.clear();
};

export const useLocalStorage = (key: string, defaultValue?: string) => {
  const [value, setValue] = useState<string | undefined>(() => getStorageValue(key, defaultValue));

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, value ?? '');
  }, [key, value]);

  return { value, setValue };
};

import React from 'react';

import clsx from 'clsx';

export interface IRadioButton extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name?: string;
  value: string;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  dataTestId?: string;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
}

export const RadioButton = ({
  id,
  name,
  value,
  label,
  onChange,
  checked,
  dataTestId,
  className,
  containerClassName,
  labelClassName,
  ...props
}: IRadioButton) => (
  <div className={`${containerClassName || 'radio-button-container'} relative flex items-start`}>
    <div className="relative">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        data-testid={dataTestId}
        className={`${clsx(className, 'transition-border ease relative m-0 flex size-5 cursor-pointer appearance-none items-center justify-center rounded-full border border-gray-400 bg-white transition-colors duration-100 checked:border-navyBlue checked:bg-navyBlue focus:outline-none focus:ring-0 focus-visible:ring-0')}`}
        {...props}
      />
      {checked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 5 5"
          className="absolute left-1/2 top-1/2 size-2 -translate-x-1/2 -translate-y-1/2 scale-100 text-white"
          aria-hidden="true"
        >
          <path fill="currentColor" d="M0 2.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"></path>
        </svg>
      )}
    </div>
    <label htmlFor={id} className={` ${clsx(labelClassName, 'mb-0 cursor-pointer pl-3 text-sm')}`}>
      {label}
    </label>
  </div>
);

RadioButton.displayName = 'RadioButton';

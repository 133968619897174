import React, { forwardRef, type HTMLAttributes } from 'react';
import { Tooltip } from 'react-tooltip';

import { cn } from './utils/cn';
import { COLOR, THEME_ICON_STYLE_VARIANT } from './utils/enums';
import formatIcon from './utils/formatIcon';

export interface IThemeIcon extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  styleVariant?:
    | 'black'
    | 'gray'
    | 'pink'
    | 'pinkWithBackground'
    | 'turquoise'
    | 'accentBlue'
    | 'check'
    | 'warning'
    | 'error';
  icon: React.ReactNode;
  className?: string;
  adjustedSize?: string | number;
  title: string;
}

export const ThemeIcon = forwardRef<HTMLDivElement, IThemeIcon>(
  ({ styleVariant = 'black', className, icon, adjustedSize, title, ...props }, ref) => {
    const classNames = cn(
      'bg-transparent h-9 w-9 rounded-[5px] grid place-content-center',
      {
        'text-black': styleVariant === COLOR.BLACK,
        'text-gray-500': styleVariant === COLOR.GRAY,
        'text-pink': styleVariant === COLOR.PINK,
        'bg-gray-50 text-pink': styleVariant === THEME_ICON_STYLE_VARIANT.PINK_WITH_BACKGROUND,
        'text-primary': styleVariant === COLOR.TURQUOISE,
        'text-accentBlue': styleVariant === COLOR.ACCENT_BLUE,
        'text-check': styleVariant === COLOR.CHECK,
        'text-warning': styleVariant === COLOR.WARNING,
        'text-red-500': styleVariant === COLOR.ERROR,
      },
      className,
    );

    return (
      <>
        <div
          data-tooltip-id={title}
          data-tooltip-content={title}
          data-component="ThemeIcon"
          className={classNames}
          ref={ref}
          {...props}
        >
          {formatIcon(icon, {
            strokeWidth: '1',
            size: adjustedSize ? adjustedSize : 20,
          })}
        </div>
        <Tooltip id={title} />
      </>
    );
  },
);

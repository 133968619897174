import React, {
  type ChangeEvent,
  type FocusEvent,
  forwardRef,
  type InputHTMLAttributes,
  type KeyboardEvent,
  type ReactNode,
} from 'react';

import clsx from 'clsx';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  rightSection?: ReactNode;
  leftSection?: ReactNode;
  label?: ReactNode;
  className?: string;
  min?: string | undefined | number;
  max?: string | undefined | number;
  error?: string;
  showRightSection?: boolean;
  step?: string;
  autoFocus?: boolean;
  wrapperClassName?: string;
  maxLength?: undefined | number;
  autoComplete?: string;
  enablePasswordManagerAutofill?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      rightSection,
      leftSection,
      className,
      error,
      autoFocus,
      label,
      maxLength,
      enablePasswordManagerAutofill = false,
      onBlur,
      disabled,
      onKeyDown,
      min = '0',
      max,
      autoComplete,
      showRightSection = true,
      step,
      wrapperClassName,
      ...props
    },
    ref,
  ) => (
    <div className={clsx('TextInputWrapper relative', wrapperClassName)}>
      {label && <label className="mb-2 flex items-center gap-1 text-xs font-medium text-black">{label}</label>}

      {leftSection && (
        <div
          className={clsx(
            'absolute left-0 top-[24px] flex h-10 w-12 content-center items-center justify-center align-middle text-sm font-medium text-black',
          )}
        >
          {leftSection}
        </div>
      )}

      <input
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        min={min}
        max={max}
        autoFocus={autoFocus}
        disabled={disabled}
        step={step}
        maxLength={maxLength}
        className={clsx(
          `${disabled ? 'text-gray-500' : 'text-gray-900'} h-10 w-full rounded-[4px] border border-gray-200 bg-gray-50 px-3 text-sm outline-none selection:bg-turquoise-200 focus:border-turquoise-500 focus:ring-turquoise-500 active:border-turquoise-500 active:ring-turquoise-500 ${rightSection && 'pr-12'} ${leftSection && 'pl-12'} ${
            error &&
            'border-red-500 text-red-500 selection:bg-red-200 focus:border-red-500 focus:ring-red-500 focus:placeholder:text-red-500 active:border-red-500 active:ring-red-500'
          }`,
          className,
        )}
        data-lpignore={enablePasswordManagerAutofill ? undefined : 'true'} // lastpass
        data-1p-ignore={enablePasswordManagerAutofill ? undefined : 'true'} // 1Password
        ref={ref}
        {...props}
      />
      {rightSection && showRightSection && (
        <div className="absolute inset-y-0 right-0 flex w-12 content-center items-center justify-center align-middle text-sm font-medium text-black">
          {rightSection}
        </div>
      )}
      {error && <div className="mt-2 text-xs text-red-500">{error}</div>}
    </div>
  ),
);

import * as React from 'react';
import { type SVGProps } from 'react';
export const IconAccounts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.11108 7.22221C4.11108 8.87245 4.76664 10.4551 5.93353 11.622C7.10042 12.7889 8.68306 13.4444 10.3333 13.4444C11.9835 13.4444 13.5662 12.7889 14.7331 11.622C15.9 10.4551 16.5555 8.87245 16.5555 7.22221C16.5555 5.57198 15.9 3.98934 14.7331 2.82244C13.5662 1.65555 11.9835 1 10.3333 1C8.68306 1 7.10042 1.65555 5.93353 2.82244C4.76664 3.98934 4.11108 5.57198 4.11108 7.22221Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 29V25.8889C1 24.2386 1.65555 22.656 2.82244 21.4891C3.98934 20.3222 5.57198 19.6666 7.22221 19.6666H13.4444C15.0947 19.6666 16.6773 20.3222 17.8442 21.4891C19.0111 22.656 19.6666 24.2386 19.6666 25.8889V29"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2222 1.20222C22.5606 1.54491 23.7469 2.32331 24.594 3.4147C25.4412 4.5061 25.901 5.8484 25.901 7.22999C25.901 8.61159 25.4412 9.95389 24.594 11.0453C23.7469 12.1367 22.5606 12.9151 21.2222 13.2578"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9999 29V25.8889C28.992 24.5156 28.53 23.1835 27.6859 22.1002C26.8418 21.0169 25.663 20.2433 24.3333 19.9"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

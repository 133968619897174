import { type ReactNode, useEffect, useState } from 'react';

import { IconAlertCircle, IconCircleCheck } from '@tabler/icons-react';

import { cn } from './utils/cn';
import { COLOR } from './utils/enums';

interface IAlert {
  show: boolean;
  message: ReactNode;
  type: 'warning' | 'error' | 'success';
  'data-testid'?: string;
  plain?: boolean;
  /**
   * @deprecated Can be removed entirely.
   */
  closable?: boolean;
  className?: string;
}

export const Alert = ({ show, message, type, plain = false, className, 'data-testid': dataTestId }: IAlert) => {
  const [showAlert, setShowAlert] = useState<boolean>();

  const icon = () => {
    if (plain) return null;

    switch (type) {
      case 'warning':
        return <IconAlertCircle className="hidden text-warning md:block" size={26} />;
      case 'error':
        return <IconAlertCircle className="hidden text-red-500 md:block" size={26} />;
      case 'success':
        return <IconCircleCheck className="hidden text-green md:block" size={26} />;
    }
  };

  useEffect(() => {
    setShowAlert(show);
  }, [show]);

  if (!showAlert) return null;

  return (
    <div
      className={cn(
        `flex w-full justify-between gap-3 rounded border border-solid p-5 md:items-center md:gap-12 ${
          plain ? 'py-4 font-medium' : ''
        }`,
        {
          'border-warning bg-warningLight text-warning': type === COLOR.WARNING,
          'border-red-500 bg-red-50 text-red-500': type === COLOR.ERROR,
          'border-green bg-greenLight text-green': type === COLOR.SUCCESS,
        },
        className,
      )}
      data-component="Alert"
      data-testid={dataTestId}
    >
      <>
        <div className="flex md:items-center">
          <div className="flex md:items-center">{icon()}</div>
          <p className={`m-0 font-medium text-black ${plain ? '' : 'md:ml-5'}`}>{message}</p>
        </div>
      </>
    </div>
  );
};

Alert.displayName = 'Alert';

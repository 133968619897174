import { IconCheck } from '@tabler/icons-react';
import clsx from 'clsx';

import useIsMobile from '@/utils/hooks/useIsMobile';

export interface Step {
  label: string;
  value: string;
}

interface StepperProps {
  steps: Step[];
  clickableSteps?: number[];
  setStep?: (step: number) => void;
  variant?: 'small' | 'default';
  activeStep: number;
  className?: string;
}

export const Stepper = ({
  steps,
  setStep = () => {},
  variant = 'default',
  clickableSteps,
  activeStep,
  className,
}: StepperProps) => {
  const isMobile = useIsMobile();

  return (
    <div className={clsx('flex w-full justify-start', className)}>
      {steps?.map((step, index) => {
        const isClickable = index <= activeStep && clickableSteps?.includes(index);
        return (
          <div key={index} className={'flex w-full'}>
            <div className={'relative flex w-full justify-center'}>
              <div
                className={`mr-2 flex w-36 flex-col items-center ${variant === 'small' || isMobile ? 'gap-0.5' : 'gap-3'}`}
              >
                {/* Step circle */}
                <div
                  onClick={() => isClickable && setStep(index)}
                  className={`relative z-10 flex ${variant === 'small' || isMobile ? 'size-5' : 'size-8'} items-center justify-center rounded-full ${isClickable && 'cursor-pointer'} ${clickableSteps && !clickableSteps.includes(index) && 'cursor-not-allowed'} ${
                    index < activeStep ? 'bg-lightBlue' : 'bg-white'
                  } ${
                    // if the step is completed, show the lightBlue color
                    // else show the gray color
                    index <= activeStep
                      ? 'border border-lightBlue text-lightBlue'
                      : 'border border-gray-500 text-gray-500'
                  }`}
                >
                  {index < activeStep && (
                    <div className="flex items-center justify-center text-white">
                      <IconCheck size={variant === 'small' || isMobile ? 7 : 12} />
                    </div>
                  )}
                  {index === activeStep && (
                    <div
                      className={`${variant === 'small' || isMobile ? 'size-1' : 'size-2'} rounded-full bg-lightBlue`}
                    ></div>
                  )}
                </div>

                {/* Label of step */}
                <div className="flex flex-wrap items-center justify-center">
                  <span
                    className={`text-center ${variant === 'small' || isMobile ? 'text-xs-fields' : 'text-sm'} ${
                      index <= activeStep ? 'text-gray-900' : 'text-gray-500'
                    }`}
                  >
                    {step.label}
                  </span>
                </div>

                {/* Line that connects the steps */}
                {index < steps.length && index !== 0 && (
                  <div
                    className={`${variant === 'small' || isMobile ? 'top-2.5' : 'top-4'} absolute z-0 h-px w-full -translate-x-1/2 ${index < activeStep + 1 ? 'bg-lightBlue' : 'bg-gray-200'}`}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

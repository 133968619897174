import { v4 } from 'uuid';

import { type ToastType } from './Toast';
import { useToastDispatchContext } from './ToastContext';

interface Props {
  title?: string;
  message: string;
  type: ToastType;
  timeout?: number | false;
}

export const useAddToast = () => {
  const dispatch = useToastDispatchContext();

  const addToast = ({ message, type, timeout }: Props) => {
    if (dispatch) {
      dispatch({
        type: 'ADD',
        toast: {
          id: v4(),
          message,
          type,
          timeout,
        },
      });
    }
  };
  return addToast;
};

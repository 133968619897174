import { type KeyboardEvent, type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'next-i18next';

import { IconX } from '@tabler/icons-react';

import { cn } from './utils/cn';
import { ActionIcon } from './ActionIcon';
interface IModal {
  children: ReactNode;
  title?: string;
  withCloseButton?: boolean;
  opened: boolean;
  onClose: () => void;
  additionalClassNames?: string;
  gradient?: boolean;
  dataTestId?: string;
  size?: string | number;
  uncloseAble?: boolean;
  closeModalWithConfirmation?: boolean;
}

export const Modal = ({
  title,
  children,
  withCloseButton = true,
  opened,
  additionalClassNames,
  closeModalWithConfirmation = false,
  gradient = true,
  uncloseAble = false,
  dataTestId,
  onClose,
  size,
}: IModal) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const refContainer = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(opened);
  }, [opened]);

  const handleClose = useCallback(() => {
    if (uncloseAble) return;
    if (closeModalWithConfirmation) {
      if (window.confirm(t('globals.closeModalWithConfirmation'))) {
        setIsOpen(false);
        onClose();
      }
    } else {
      setIsOpen(false);
      onClose();
    }
  }, [closeModalWithConfirmation, onClose, t, uncloseAble]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (refContainer.current && refContainer.current.contains(event.target as HTMLElement))
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) handleClose();
    },
    [handleClose],
  );

  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') handleClose();
    },
    [handleClose],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('keydown', handleEscapeKey as unknown as EventListener);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('keydown', handleEscapeKey as unknown as EventListener);
    };
  }, [handleClickOutside, handleClose, handleEscapeKey, isOpen, onClose, opened, ref]);

  if (!isOpen) return null;

  return createPortal(
    <div
      className={cn(
        'fixed left-0 top-0 z-[999] grid h-full w-full items-center overflow-y-scroll overscroll-contain px-4 py-10 md:py-20',
      )}
      data-testid={dataTestId}
      data-component="modal"
      ref={refContainer}
    >
      <div className="fixed left-0 top-0 z-[-1] size-full bg-navyBlue bg-opacity-40" data-testid="modalBg" />
      <div className={'relative flex w-full justify-center'}>
        <section
          className={cn(
            'relative h-full w-full max-w-[1000px] bg-white px-5 py-8 shadow-xl md:px-10 md:py-10',
            additionalClassNames,
            size,
          )}
          ref={ref}
          role="dialog"
          tab-index="-1"
          aria-modal={isOpen}
          aria-label={title}
        >
          <div className="relative z-10 w-full">
            {withCloseButton && (
              <ActionIcon
                onClick={handleClose}
                aria-label={t('globals.closeModal')}
                className="absolute right-0 top-0"
                data-testid="close-modal"
                icon={<IconX />}
                title={t('globals.closeModal')}
              />
            )}
          </div>
          {title && (
            <div className="relative w-full pt-14">
              <h2 className="mb-8 mt-0 text-left text-3xl font-bold">{title}</h2>
            </div>
          )}
          <div>{children}</div>
          {gradient && (
            <hr className="absolute bottom-0 left-0 mb-0 mt-8 !h-2 w-full border-none bg-gradient-blue-pink" />
          )}
        </section>
      </div>
    </div>,
    document.body,
    // Below is needed for test.tsx and for stories.tsx
  ) as unknown as JSX.Element;
};

Modal.displayName = 'Modal';

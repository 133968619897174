import { forwardRef, type ReactNode, type Ref } from 'react';
import { useTranslation } from 'next-i18next';

import { IconHeart, IconHeartFilled } from '@tabler/icons-react';

import { ActionIcon } from './ActionIcon';

interface IFavouriteButtonProps {
  onClick: () => void;
  favourite: boolean;
  iconOutline?: ReactNode;
  iconFilled?: ReactNode;
  /**
   * @deprecated Can be removed and replaced by iconSize
   */
  width?: string;
  /**
   * @deprecated Can be removed and replaced by iconSize
   */
  height?: string;
  iconSize?: number;
  className?: string;
  'data-testid'?: string;
  iconColor?: string;
  iconColorSelected?: string;
  styleVariant?: 'transparent' | 'small' | 'default' | 'round' | undefined;
}

export const FavouriteButton = forwardRef(
  (
    {
      onClick,
      favourite,
      iconFilled,
      iconOutline,
      iconColorSelected = 'text-pink',
      iconColor = 'text-gray-800',
      iconSize,
      className,
      styleVariant = 'transparent',
      ...props
    }: IFavouriteButtonProps,
    ref: Ref<HTMLButtonElement & HTMLAnchorElement>,
  ) => {
    const { t } = useTranslation();
    return (
      <ActionIcon
        onClick={onClick}
        data-component="favouriteButton"
        title={t('globals.favourite')}
        styleVariant={styleVariant}
        className={`${favourite ? iconColorSelected : iconColor} ${className}`}
        iconSize={iconSize}
        {...props}
        ref={ref}
        icon={favourite ? (iconFilled ?? <IconHeartFilled />) : (iconOutline ?? <IconHeart />)}
      />
    );
  },
);

FavouriteButton.displayName = 'FavouriteButton';

import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { IconDotsVertical } from '@tabler/icons-react';

import { cn } from './utils/cn';
import { buttonVariants, UnstyledButton } from '.';

interface IKebabMenu {
  title?: string;
  disabled?: boolean;
  content: {
    key: number;
    onClick: () => void;
    title: string;
  }[];
}

export const KebabMenu = ({ title, content, disabled = false }: IKebabMenu) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const defaultStyling =
    'text-black flex items-center gap-1 bg-transparent font-medium py-1 h-9 min-w-9 cursor-pointer px-0';

  const handleToggle = () => {
    if (!disabled) setIsOpen((prev) => !prev);
  };

  const handleItemClick = (onClick: () => void) => {
    if (!disabled) {
      onClick();
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className="relative"
        data-component="kebabMenu"
        data-testid="kebab-menu"
        ref={menuRef}
        data-tooltip-id={title}
        data-tooltip-content={title}
      >
        <UnstyledButton
          aria-label="Options Menu"
          className={cn(buttonVariants['secondary'], defaultStyling, {
            'md:pl-5 md:pr-3.5': title,
          })}
          disabled={disabled}
          onClick={handleToggle}
        >
          {title && <span className="hidden md:block">{title}</span>}
          <IconDotsVertical size={20} stroke={1} fill="black" />
        </UnstyledButton>
        {isOpen && (
          <div className="absolute right-0 z-10 mt-2 w-auto min-w-[200px] rounded-md border-[0.0625rem] border-gray-200 bg-white p-1 shadow-md">
            {content.map((c, index) => (
              <UnstyledButton
                key={index}
                onClick={() => handleItemClick(c.onClick)}
                className="w-full whitespace-nowrap rounded-md px-4 py-2 text-left text-sm hover:bg-gray-50"
                aria-label="Options Menu Item"
              >
                {c.title}
              </UnstyledButton>
            ))}
          </div>
        )}
      </div>
      <Tooltip id={title} />
    </>
  );
};

KebabMenu.displayName = 'KebabMenu';

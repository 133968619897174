import { cn } from './utils/cn';

type IUnorderedListProps = {
  children: React.ReactNode;
  className?: string;
  actionItem?: React.ReactNode;
};

export const UnorderedList = ({ children, className, actionItem, ...props }: IUnorderedListProps) => (
  <ul className={cn('m-0 last:border-none', className)} data-component="unorderedList" {...props}>
    {children}
    {actionItem && <span className="flex items-center">{actionItem}</span>}
  </ul>
);

import { cloneElement, forwardRef, isValidElement, type ReactNode, useImperativeHandle, useState } from 'react';

import {
  type AlignedPlacement,
  autoUpdate,
  flip,
  offset,
  shift,
  type Side,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { IconX } from '@tabler/icons-react';
import clsx from 'clsx';

import { useBreakPoints } from '@/utils/hooks/useIsMobileOrTablet';

import { cn } from './utils/cn';
import { ActionIcon } from './ActionIcon';

interface ITooltipProps {
  content: ReactNode;
  children: ReactNode;
  hoverEnabled?: boolean;
  passedOpen?: boolean;
  placement?: AlignedPlacement | Side;
  amountOfOffset?: number;
  className?: string;
  style?: 'minimal' | 'card';
}

export const Popover = forwardRef(
  (
    {
      content,
      children,
      hoverEnabled = false,
      passedOpen = false,
      placement = 'right',
      style = 'card',
      className,
    }: ITooltipProps,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(passedOpen);
    const { mobile } = useBreakPoints();

    const mobileStyles = {
      width: `${style === 'card' && '100%'}`,
    };

    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: !mobile ? placement : 'bottom-start',
      middleware: [!mobile && flip(), !mobile && shift(), offset(15)],
      whileElementsMounted: autoUpdate,
    });

    const click = useClick(context, { event: 'click', keyboardHandlers: true });
    const focus = useFocus(context);
    const hover = useHover(context, {
      enabled: hoverEnabled,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const interactions = [click, focus, dismiss, role, hover];

    const { getReferenceProps, getFloatingProps } = useInteractions(interactions);

    // Use useImperativeHandle to expose closePopover to the parent
    useImperativeHandle(ref, () => ({
      closePopover: () => setIsOpen(false),
      openPopover: () => setIsOpen(true),
      togglePopover: () => setIsOpen((prev) => !prev),
    }));

    return (
      <div className={cn('', { '[&>div]:border-navyBlue': isOpen }, className)}>
        {isValidElement(children) &&
          cloneElement(
            children,
            getReferenceProps({
              ref: refs.setReference,
              ...children.props,
              ...getReferenceProps(),
            }),
          )}

        {isOpen && (
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...(mobile && { ...mobileStyles }) }}
            {...getFloatingProps()}
            className={clsx('z-[60]', {
              'p-5 bg-white border w-fit !border-gray-200 rounded': style === 'minimal',
              'card w-[489px] drop-shadow-xl': style === 'card',
            })}
            data-component="Popover"
          >
            {style === 'minimal' && content}
            {style === 'card' && (
              <div className="bg-linear-gradient-x pt-[3px]">
                <div className="relative overflow-hidden bg-white p-5">
                  {!hoverEnabled && (
                    <ActionIcon
                      onClick={() => setIsOpen(false)}
                      title={''}
                      icon={<IconX />}
                      styleVariant="small"
                      className="absolute right-5 top-5"
                    />
                  )}
                  {content}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);

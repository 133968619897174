import React, { useEffect, useState } from 'react';

import { cn } from './utils/cn';

interface ProgressProps {
  value: number;
  maxValue?: number;
  className?: string;
}

export const ProgressBar = ({ value, maxValue = 100, className }: ProgressProps) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((value / maxValue) * 100);
  }, [value, maxValue]);

  return (
    <div className={cn('h-2 rounded-lg bg-gray-100', className)}>
      <div
        className="h-2 rounded-lg bg-[#27295c] transition-all duration-1000 ease-in-out"
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
};

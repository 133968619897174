import { createContext, type Dispatch, useContext } from 'react';

import { type ToastProps } from './Toast';

export interface ToastDispatchProps {
  type: 'ADD' | 'DELETE';
  toast: ToastProps;
}

export const ToastStateContext = createContext<ToastProps[]>([]);
export const ToastDispatchContext = createContext<Dispatch<ToastDispatchProps> | undefined>(undefined);

export const useToastStateContext = () => useContext(ToastStateContext);
export const useToastDispatchContext = () => useContext(ToastDispatchContext);

import { type ReactNode } from 'react';

import { cn } from './utils/cn';

type IUnorderedListProps = {
  children: ReactNode;
  actionItem?: ReactNode;
  className?: string;
  header?: boolean;
};

export const UnorderedListItem = ({
  children,
  actionItem,
  className,
  header = false,
  ...props
}: IUnorderedListProps) => (
  <li
    {...props}
    data-component="unorderedListItem"
    className={cn(
      'grid min-h-[74px] list-none items-center border-b border-solid border-b-gray-200 py-5 text-sm last:border-none md:flex md:py-4 md:pl-10 md:pr-10',
      header ? 'md:items-end' : 'hover:bg-ultraLightBlue',
      {
        'grid-cols-2': actionItem,
      },
      className,
    )}
  >
    {children}

    {actionItem && <span className="flex justify-end gap-2 md:absolute md:right-8 md:items-center">{actionItem}</span>}
  </li>
);

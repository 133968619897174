import clsx from 'clsx';

import { cn } from './utils/cn';
import { COLOR } from './utils/enums';
interface IBadgeProps {
  styleVariant?:
    | 'greenLight'
    | 'red'
    | 'accentBlue'
    | 'warning'
    | 'gray'
    | 'black'
    | 'purple'
    | 'pink'
    | 'pinkLight'
    | 'gray'
    | 'white';
  color?:
    | 'dark'
    | 'gray'
    | 'red'
    | 'pink'
    | 'grape'
    | 'violet'
    | 'indigo'
    | 'blue'
    | 'cyan'
    | 'green'
    | 'lime'
    | 'yellow'
    | 'orange'
    | 'teal';
  /** Controls appearance */
  variant?: 'light' | 'filled' | 'outline' | 'dot' | 'gradient';
  /** Badge height and font size */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /** Key of theme.radius or any valid CSS value to set border-radius, "xl" by default */
  radius?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /** Sets badge width to 100% of parent element, hides overflow text with text-overflow: ellipsis */
  fullWidth?: boolean;
  /** Section rendered on the left side of label */
  leftSection?: React.ReactNode;
  /** Section rendered on the right side of label */
  rightSection?: React.ReactNode;
  favorite?: boolean;
  /** Badge label */
  children?: React.ReactNode;
  mobile?: boolean;
  className?: string;
  badgeBorder?: boolean;
}

export const Badge = ({
  styleVariant = COLOR.ACCENT_BLUE,
  className,
  favorite,
  mobile = false,
  badgeBorder = false,
  ...props
}: IBadgeProps) => (
  <span
    data-component="Badge"
    className={clsx(
      'whitespace-wrap inline-flex h-[1.375rem] max-w-full content-center items-center justify-center text-wrap p-[10px] align-middle text-xs font-medium normal-case leading-[20px] tracking-wide',
      mobile ? 'rounded-s' : 'rounded-[2rem]',
      badgeBorder && {
        'border-accentBlue': styleVariant === COLOR.ACCENT_BLUE,
        'border-green': styleVariant === COLOR.GREEN_LIGHT,
        'border-red-500': styleVariant === COLOR.RED,
        'border-purple': styleVariant === COLOR.PURPLE,
        'border-warning': styleVariant === COLOR.WARNING,
        'border-black': styleVariant === COLOR.BLACK,
        'border-gray-800': styleVariant === COLOR.GRAY,
        'border-pink': styleVariant === COLOR.PINK_LIGHT || styleVariant === COLOR.PINK,
        'border-gray-200 bg-white text-black': styleVariant === COLOR.WHITE,
      },
      {
        'bg-accentBlueLight text-accentBlue': styleVariant === COLOR.ACCENT_BLUE,
        'bg-greenLight text-green': styleVariant === COLOR.GREEN_LIGHT,
        'bg-red-50 text-red-500': styleVariant === COLOR.RED,
        'bg-purpleLight text-purple': styleVariant === COLOR.PURPLE,
        'bg-warningLight text-warning': styleVariant === COLOR.WARNING,
        'bg-gray-50 text-black': styleVariant === COLOR.BLACK,
        'bg-gray-50 text-gray-800': styleVariant === COLOR.GRAY,
        'bg-pinkLight text-pink': styleVariant === COLOR.PINK_LIGHT,
        'bg-pink text-white': styleVariant === COLOR.PINK,
        'bg-white text-black': styleVariant === COLOR.WHITE,
      },
      className,
    )}
    {...props}
  >
    <span
      className={cn('block h-5 overflow-hidden text-ellipsis whitespace-nowrap', {
        'flex items-center': favorite,
      })}
    >
      {props.children}
    </span>
  </span>
);

Badge.displayName = 'Badge';

import { cn } from './utils/cn';

interface IAvatarProps {
  'data-testid'?: string;
  src: string;
  alt?: string;
  size?: number;
  radius?: keyof typeof radiusFormatter;
}

const radiusFormatter = {
  0: 'rounded-none',
  2: 'rounded-sm',
  4: 'rounded',
  6: 'rounded-lg',
  8: 'rounded-xl',
  10: 'rounded-2xl',
  12: 'rounded-3xl',
  100: 'rounded-full',
};

export const Avatar = ({ src, alt = '', size = 50, radius = 100 }: IAvatarProps) => (
  <div data-component="Avatar" data-testid="avatar">
    {src && (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={src}
        className={cn('aspect-square appearance-none object-cover', radiusFormatter[radius])}
        alt={alt}
        width={size}
        height={size}
      />
    )}
  </div>
);

Avatar.displayName = 'Avatar';

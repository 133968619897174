import { useToastDispatchContext } from './ToastContext';

export const useDeleteToast = () => {
  const dispatch = useToastDispatchContext();

  const deleteToast = (id: string) => {
    if (dispatch) {
      dispatch({
        type: 'DELETE',
        toast: {
          id: id,
        },
      });
    }
  };

  return deleteToast;
};

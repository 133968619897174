import { type ButtonHTMLAttributes, forwardRef } from 'react';

import { cn } from './utils/cn';

interface IUnstyledButton extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  className?: string;
}

export const UnstyledButton = forwardRef<HTMLButtonElement, IUnstyledButton>(({ className, ...props }, ref) => (
  <button data-component="unstyledButton" className={cn('appearance-none', className)} ref={ref} {...props} />
));

UnstyledButton.displayName = 'UnstyledButton';

import { type ReactNode, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import { getInstance } from '@eppo/js-client-sdk';

import { getPageRoute } from '@/utils/constants/pageRoutes';

import { useAuth } from '../auth/AuthContext';

interface IMaintenanceProviderProps {
  children: ReactNode;
}
export const MaintenanceProvider = ({ children }: IMaintenanceProviderProps) => {
  const { logout, setIsMaintenance } = useAuth();
  const router = useRouter();

  const maintenanceMode = useMemo(() => {
    const client = getInstance();
    return client.getBooleanAssignment('maintenance-mode', 'no-user-id', {}, false);
  }, []);

  const path = router.pathname.split('/').length < 3 ? '/' : `/${router?.pathname?.split('/')?.[3]}`;

  useEffect(() => {
    if (router.isReady) {
      (async () => {
        if (maintenanceMode && path !== '/maintenance') {
          setIsMaintenance(true);
          await logout();
          router.push(getPageRoute('maintenance', router.query.region as string, router.query.lang as string));
        } else if (!maintenanceMode && path === '/maintenance') {
          setIsMaintenance(false);
          router.push(getPageRoute('login', router.query.region as string, router.query.lang as string));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceMode, router.isReady]);

  return <>{children}</>;
};

import React from 'react';
import { useParams, useRouter } from 'next/navigation';
import { useTranslation } from 'next-i18next';

import { getPageRoute, type RouteName } from '@/utils/constants/pageRoutes';
import { type ILanguage, type translationKey } from '@/utils/i18n/languages';

import { type ISelectFormValues } from '../settings/templates/AddEditCloneModalTemplate';

import { Select } from './Select';

export type Language = {
  id: string;
  i18n: string;
  serverKey: string;
  enabled?: boolean;
  translationKey: translationKey;
  supportEmail: string;
  defaultLanguage: boolean;
};

interface ILanguageSwitch {
  label: string;
  defaultLanguage: Language | undefined;
  languages: Language[];
  className?: string;
  page: 'register' | 'login' | 'forgotPassword' | 'register2' | RouteName;
  currentLanguage?: ILanguage;
  onChange?: (lang: ISelectFormValues) => void;
}

export const LanguageSelect = ({
  currentLanguage,
  languages,
  defaultLanguage,
  page,
  label,
  className,
  onChange,
}: ILanguageSwitch) => {
  const { t } = useTranslation();

  const router = useRouter();
  const params = useParams<{ lang: string; region: string }>();

  const reformattedData = languages?.map((language: Language) => ({
    value: language.i18n,
    label: t(language.translationKey),
  }));

  const value = defaultLanguage?.i18n || currentLanguage?.i18n;

  return (
    <Select
      label={label}
      data-component="languageSelect"
      className={className}
      value={value}
      data-testid="language-switch"
      options={reformattedData}
      showLangFlags={true}
      placeholder={(currentLanguage?.translationKey && t(currentLanguage?.translationKey)) || ''}
      onChange={(language: ISelectFormValues) => {
        if (onChange) onChange(language);
        else if (page) router.push(getPageRoute(page, params.region ?? 'nl', language?.value ?? 'nl-NL'));
      }}
      id={'language-switch'}
    />
  );
};

LanguageSelect.displayName = 'LanguageSelect';

import { getStorageValue, removeStorage, setStorageValue } from '../storage/storage';

export type RegionId = 'nl' | 'en' | 'be' | 'fr';
export type translationKeys = 'regions.nl' | 'regions.uk' | 'regions.be' | 'regions.fr';
export interface IRegion {
  id: 'nl' | 'en' | 'be' | 'fr';
  slug: string;
  supportEmailAddress: string;
  flag: string;
  translationKey: translationKeys;
  apiUrl?: string;
  consentUrl: string;
  hideConsentWithModelAgreement: boolean;
  postalCodeValidation: RegExp;
  enabled: boolean;
  locale: string;
  localeLocation: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  hubspotFormID: string;
  countryName?: string;
}

const regions: IRegion[] = [
  {
    id: 'nl',
    slug: 'nl',
    locale: 'nl-NL',
    flag: '/images/countries/NL_flag.svg',
    translationKey: 'regions.nl',
    postalCodeValidation: /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/,
    supportEmailAddress: 'support@youngones.works',
    consentUrl: 'https://youngones.com/nl/algemene-voorwaarden/',
    hideConsentWithModelAgreement: false,
    apiUrl: process.env.NEXT_PUBLIC_DASHBOARD_API_NL_URL,
    enabled: true,
    localeLocation: 'Europe/Amsterdam',
    coordinates: {
      lat: 52.079394,
      lng: 5.260525,
    },
    hubspotFormID: 'fd042b9c-5760-47f7-ab50-3691cb3e6cd5',
  },
  {
    id: 'en',
    slug: 'gb',
    locale: 'en-GB',
    flag: '/images/countries/UK_flag.svg',
    translationKey: 'regions.uk',
    postalCodeValidation: /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/,
    supportEmailAddress: 'support.uk@youngones.com',
    consentUrl: 'https://youngones.com/uk/terms-and-conditions/',
    hideConsentWithModelAgreement: true,
    apiUrl: process.env.NEXT_PUBLIC_DASHBOARD_API_GB_URL,
    enabled: true,
    localeLocation: 'Europe/London',
    coordinates: {
      lat: 51.9859,
      lng: -1.067426,
    },
    hubspotFormID: 'b833796d-7085-4aee-8dae-9f40888a0dcf',
  },
  {
    id: 'be',
    slug: 'be',
    locale: 'nl-BE',
    flag: '/images/countries/BE_flag.svg',
    translationKey: 'regions.be',
    postalCodeValidation: /^[1-9][0-9]{3}$/,
    supportEmailAddress: 'support.be@youngones.com',
    consentUrl: 'https://youngones.com/be/algemene-voorwaarden/',
    hideConsentWithModelAgreement: false,
    apiUrl: process.env.NEXT_PUBLIC_DASHBOARD_API_BE_URL,
    enabled: true,
    localeLocation: 'Europe/Brussels',
    coordinates: {
      lat: 50.645696,
      lng: 4.916227,
    },
    hubspotFormID: '54c3da1c-f92a-404b-b3b7-8e9ec9221692',
  },
  {
    id: 'fr',
    slug: 'fr',
    locale: 'fr-FR',
    flag: '/images/countries/FR_flag.svg',
    translationKey: 'regions.fr',
    postalCodeValidation: /^(2[AB]|[0-9]{2})[0-9]{3}$/,
    supportEmailAddress: 'support.fr@youngones.com',
    consentUrl: 'https://youngones.com/fr/conditions-generales/',
    hideConsentWithModelAgreement: false,
    apiUrl: process.env.NEXT_PUBLIC_DASHBOARD_API_FR_URL,
    enabled: true,
    localeLocation: 'Europe/Paris',
    coordinates: {
      lat: 46.446994,
      lng: 2.289132,
    },
    hubspotFormID: 'b6d414af-1f82-4453-abee-b29a2914a49a',
  },
];

const regionKey = 'YO_REGION';

export const getRegions = () => regions.filter((region) => region.enabled);

export const setRegion = (value: string) => {
  const region = regions.find((region) => region.slug === value);

  return setStorageValue(regionKey, region?.slug || 'nl');
};

export const getRegion = () => {
  const regionId = getStorageValue(regionKey);
  const region = regions.find((region) => region.slug === regionId);

  return region ? region : null;
};

export const getRegionBySlug = (slug: string): IRegion | null => {
  const region = regions.filter((region) => region.enabled).find((region) => region.slug === slug);

  return region ? region : null;
};

export function useGetCurrentRegionConfig(): IRegion {
  const selectedRegion = regions.find((region) => region.slug === getStorageValue(regionKey)) ?? regions[0];
  return selectedRegion;
}
export const removeRegion = () => {
  removeStorage(regionKey);
};

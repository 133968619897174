import * as React from 'react';
import { type SVGProps } from 'react';
export const IconAlarm = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Group 213">
      <path
        id="Vector"
        d="M2.26465 10.8191C2.26465 11.8533 2.46834 12.8773 2.8641 13.8327C3.25985 14.7882 3.83992 15.6563 4.57118 16.3876C5.30244 17.1188 6.17058 17.6989 7.12602 18.0946C8.08146 18.4904 9.10549 18.6941 10.1396 18.6941C11.1738 18.6941 12.1978 18.4904 13.1533 18.0946C14.1087 17.6989 14.9769 17.1188 15.7081 16.3876C16.4394 15.6563 17.0194 14.7882 17.4152 13.8327C17.811 12.8773 18.0146 11.8533 18.0146 10.8191C18.0146 9.78493 17.811 8.7609 17.4152 7.80546C17.0194 6.85002 16.4394 5.98189 15.7081 5.25063C14.9769 4.51936 14.1087 3.9393 13.1533 3.54354C12.1978 3.14778 11.1738 2.94409 10.1396 2.94409C9.10549 2.94409 8.08146 3.14778 7.12602 3.54354C6.17058 3.9393 5.30244 4.51936 4.57118 5.25063C3.83992 5.98189 3.25985 6.85002 2.8641 7.80546C2.46834 8.7609 2.26465 9.78493 2.26465 10.8191Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M10.1396 7.44409V10.8191H12.3896"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M4.51465 0.694092L1.4209 2.94409"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M15.7646 0.694092L18.8584 2.94409"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

import { getFromCookieStorage, writeToCookieStorage } from './cookieStorage';

export const COOKIE_CONSENT_STATISTICS = 'cookies-accepted-statistics';
export const COOKIE_CONSENT_MARKETING = 'cookies-accepted-marketing';

export function setStatisticCookieConsent(value: string) {
  writeToCookieStorage(COOKIE_CONSENT_STATISTICS, value, { expires: 365 });
}
export function setMarketingCookieConsent(value: string) {
  writeToCookieStorage(COOKIE_CONSENT_MARKETING, value, { expires: 365 });
}

export function getMarketingCookieConsent() {
  return getFromCookieStorage(COOKIE_CONSENT_MARKETING);
}
export function getStatisticsCookieConsent() {
  return getFromCookieStorage(COOKIE_CONSENT_STATISTICS);
}

import * as React from 'react';
import { type SVGProps } from 'react';
export const IconCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    viewBox="0 0 26 30"
    fill="none"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.6316 4.11113H3.94737C2.31958 4.11113 1 5.50402 1 7.22224V25.8889C1 27.6071 2.31958 29 3.94737 29H21.6316C23.2593 29 24.5789 27.6071 24.5789 25.8889V7.22224C24.5789 5.50402 23.2593 4.11113 21.6316 4.11113Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18.6842 1V7.22222" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.89462 1V7.22222" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 13.4445H24.5789" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

import { type Tuple } from '@mantine/core';

import { COLOR } from './enums';

type ColorScale = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

type ColorConfig = {
  [COLOR.PRIMARY]: string;
  [COLOR.BLACK]: string;
  [COLOR.NAVY_BLUE]: string;
  [COLOR.LIGHT_BLUE]: string;
  [COLOR.GREEN]: string;
  [COLOR.GREEN_LIGHT]: string;
  [COLOR.PINK]: string;
  [COLOR.PINK_LIGHT]: string;
  [COLOR.PURPLE]: string;
  [COLOR.PURPLE_LIGHT]: string;
  [COLOR.ACCENT_BLUE]: string;
  [COLOR.ACCENT_BLUE_LIGHT]: string;
  [COLOR.YELLOW]: string;
  [COLOR.CHECK]: string;
  [COLOR.WARNING]: string;
  [COLOR.WARNING_LIGHT]: string;
  [COLOR.WHITE]: string;
  [COLOR.ULTRA_LIGHT_BLUE]: string;
  [COLOR.ULTRA_LIGHT_GRAY]: string;
  [COLOR.TURQUOISE]: ColorScale;
  [COLOR.ORANGE]: string;
  [COLOR.ORANGE_LIGHT]: string;
  [COLOR.RED]: ColorScale;
  [COLOR.GRAY]: ColorScale;
  [COLOR.NAVY_BLUE_SHADES]: ColorScale;
};

// Mantine types
export type MantineXplosiveThemeColors = COLOR.TURQUOISE | COLOR.RED | COLOR.GRAY | COLOR.NAVY_BLUE_SHADES;

// Tailwind types

/* ======
 * Config
 * ====== */

export const config: ColorConfig = {
  primary: '#10D1BB',
  black: '#1B1C1E',
  navyBlue: '#000440',
  lightBlue: '#26295C',
  green: '#1D9856',
  greenLight: '#ECFDF3',
  pink: '#E2237A',
  pinkLight: '#FCE9F2',
  purple: '#420089',
  purpleLight: '#F2E6FF',
  accentBlue: '#0066CC',
  orange: '#E86427',
  orangeLight: '#FFF3EB',
  accentBlueLight: '#E7F0F9',
  yellow: '#FFC048',
  check: '#2DC071',
  warning: '#EE9603',
  warningLight: '#FFF4E2',
  white: '#FFFFFF',
  ultraLightBlue: '#FAFCFF',
  ultraLightgray: '#FBFBFB',
  turquoise: {
    50: '#E7FAF8',
    100: '#CFF6F1',
    200: '#B7F1EB',
    300: '#9FEDE4',
    400: '#88E8DD',
    500: '#10D1BB',
    600: '#0CC2AD',
    700: '#08B39F',
    800: '#04A693',
    900: '#037A6D',
  },
  red: {
    50: '#FBE8E9',
    100: '#F7D2D3',
    200: '#E6777A',
    300: '#E26064',
    400: '#DD494D',
    500: '#D51C21',
    600: '#C0191E',
    700: '#AA161A',
    800: '#951417',
    900: '#801114',
  },
  gray: {
    50: '#F8F8F8',
    100: '#E0E0E0',
    200: '#CCCCCC',
    300: '#B8B8B8',
    400: '#A8A8A8',
    500: '#999999',
    600: '#919191',
    700: '#868686',
    800: '#7C7C7C',
    900: '#1B1C1E',
  },
  navyBlueShades: {
    50: '#232544',
    100: '#1C1E42',
    200: '#151840',
    300: '#0E1140',
    400: '#070B3F',
    500: '#000440',
    600: '#060934',
    700: '#090B2B',
    800: '#0B0D23',
    900: '#0C0D1E',
  },
};

/* =======
 * Mantine
 * ======= */

const colorScaleToTuple = (color: ColorScale) =>
  Object.values(color).reduce<Tuple<string, 10>>(
    (acc, cur, index) => {
      acc[index] = cur;
      return acc;
    },
    [...Array(10)].map(() => '') as Tuple<string, 10>,
  );

export const mantineConfigColors: Record<MantineXplosiveThemeColors, Tuple<string, 10>> = {
  [COLOR.TURQUOISE]: colorScaleToTuple(config.turquoise),
  [COLOR.RED]: colorScaleToTuple(config.red),
  [COLOR.GRAY]: colorScaleToTuple(config.gray),
  [COLOR.NAVY_BLUE_SHADES]: colorScaleToTuple(config.navyBlueShades),
};

export const mantineConfigPrimaryColor: MantineXplosiveThemeColors = COLOR.TURQUOISE;

/* ========
 * Tailwind
 * ======== */

export const tailwindConfigColors = config;

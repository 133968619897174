import { type SVGProps } from 'react';

export const IconNoShow = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18" fill="none">
    <path
      d="M8.11111 4.55554H15.2222C15.6937 4.55554 16.1459 4.74284 16.4793 5.07624C16.8127 5.40964 17 5.86182 17 6.33332V13.4444M15.9636 15.9493C15.731 16.0562 15.4781 16.1113 15.2222 16.1111H2.77778C2.30628 16.1111 1.8541 15.9238 1.5207 15.5904C1.1873 15.257 1 14.8048 1 14.3333V6.33332C1 5.86182 1.1873 5.40964 1.5207 5.07624C1.8541 4.74284 2.30628 4.55554 2.77778 4.55554H4.55556"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.60889 2.02933C5.75164 1.72177 5.97938 1.46144 6.26523 1.27906C6.55109 1.09668 6.88314 0.999851 7.22222 1H10.7778C11.2493 1 11.7015 1.1873 12.0349 1.5207C12.3683 1.8541 12.5556 2.30628 12.5556 2.77778V4.55556"
      stroke="#1B1C1E"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M9 9V9.00889" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M1 9.88889C4.26704 11.5354 7.9625 12.1339 11.5822 11.6027M14.4827 10.9244C15.347 10.6441 16.1886 10.2979 17 9.88889"
      stroke="#1B1C1E"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1 1L17 17" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

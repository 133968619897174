import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { JOB_SLUG } from '@/utils/enums';

import { AutoAcceptFavouritesExperiment } from '../experiments/AutoAcceptFavouritesExperiment';
import { FavoriteBadge } from '../gig/FavoriteBadge';
import { Badge } from '../ui';

import { type IJob } from '@/types';

interface IGigsBadges {
  job: IJob;
}

export const GigsBadges = ({ job }: IGigsBadges) => {
  const router = useRouter();
  const { t } = useTranslation();

  if (!job) return null;

  return (
    <div className="flex flex-wrap gap-3 xl:justify-end">
      <FavoriteBadge isFavorite={job?.isExclusivelyForFavorites} />
      <AutoAcceptFavouritesExperiment>
        {job.isAutoAcceptEnabled && <Badge styleVariant="pinkLight">{t('job.status.autoAccept')}</Badge>}
      </AutoAcceptFavouritesExperiment>
      {job.countUnhandledClaimsFromContractors && job.countUnhandledClaimsFromContractors > 0 ? (
        <Badge styleVariant="red">
          {t('job.numberOfClaims', {
            count: job.countUnhandledClaimsFromContractors,
          })}
        </Badge>
      ) : null}
      {job.countUnreadSubmittedClaimsByContractor && job.countUnreadSubmittedClaimsByContractor > 0 ? (
        <Badge styleVariant="red">
          {t('job.numberOfClaims', {
            count: job.countUnreadSubmittedClaimsByContractor,
          })}
        </Badge>
      ) : null}
      {(router?.query?.tab === JOB_SLUG.READY_FOR_EXECUTION || router?.query?.tab === JOB_SLUG.OPEN) &&
        job.countMatches !== job.numberOfContractors &&
        job.countUnreadCancelledMatchesByContractor > 0 && (
        <Badge styleVariant="red">
          {t('job.numberOfUnreadCancelledMatches', {
            count: job.countUnreadCancelledMatchesByContractor,
          })}
        </Badge>
      )}
      {job.isActionRequired && router?.query?.tab === JOB_SLUG.PAYOUT && (
        <Badge styleVariant="accentBlue">{t('job.hoursSubmitted')}</Badge>
      )}
      {job.substituteRequestCount > 0 && (
        <Badge styleVariant="warning">
          {t('job.numberOfSubstituteRequests', {
            count: job.substituteRequestCount,
          })}
        </Badge>
      )}
      {job.countPendingWorkIntents > 0 && (
        <Badge styleVariant="greenLight">
          {t('job.numberOfWorkIntents', {
            count: job.countPendingWorkIntents,
          })}
        </Badge>
      )}
      {job.countPendingWorkIntents === 0 && router?.query?.tab === JOB_SLUG.OPEN && (
        <Badge styleVariant="black">
          {t('job.numberOfWorkIntents', {
            count: job.countPendingWorkIntents,
          })}
        </Badge>
      )}
      {router?.query?.tab === JOB_SLUG.ARCHIVED && <Badge styleVariant="gray">{t('job.status.archived.title')}</Badge>}
    </div>
  );
};

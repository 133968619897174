import { type ReactNode, useReducer } from 'react';

import { type ToastProps } from './Toast';
import ToastContainer from './ToastContainer';
import { ToastDispatchContext, type ToastDispatchProps, ToastStateContext } from './ToastContext';

const ToastReducer = (state: ToastProps[], action: ToastDispatchProps) => {
  switch (action.type) {
    case 'ADD': {
      return [action.toast, ...state];
    }
    case 'DELETE': {
      const updatedNotifications = state.filter((e) => e.id !== action.toast.id);
      return [...updatedNotifications];
    }
  }
};

interface Props {
  children: ReactNode;
  autoDelete: boolean;
  dismissTime?: number;
}

export const ToastProvider = ({ children, autoDelete, dismissTime }: Props) => {
  const [state, dispatch] = useReducer(ToastReducer, []);

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        {children}
        <ToastContainer autoDelete={autoDelete} dismissTime={dismissTime} />
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

export default ToastProvider;

import { useAuth } from '@/components/auth/AuthContext';

export const useAuthorize = (requiredPermissions: string[], permissions: string[] = []) => {
  const { user, isLoading } = useAuth();

  if (isLoading) return;

  if (requiredPermissions.length === 0) return false;

  if (!user) return false;

  const userPermissions = [...user.permissions, ...permissions];

  const hasPermission = requiredPermissions.some((permission) => userPermissions.includes(permission));

  return hasPermission;
};

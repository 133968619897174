import * as React from 'react';
import { type SVGProps } from 'react';
export const IconStarHalfFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.03439 0.600543L6.77865 5.17206C6.62178 5.49001 6.31849 5.71032 5.96763 5.76123L0.923798 6.49436C0.0397701 6.62287 -0.312748 7.70859 0.326389 8.33156L3.97638 11.89C4.23003 12.1374 4.34601 12.4942 4.28594 12.8434L3.42444 17.8681C3.27341 18.7481 4.19707 19.419 4.98723 19.0038L9.49872 16.6317C9.65559 16.5493 9.82779 16.5081 9.99999 16.5081C10.1722 16.5081 10.3444 16.5493 10.5012 16.6317L15.0127 19.0038C15.8029 19.4194 16.7266 18.7481 16.5755 17.8681L15.714 12.8434C15.654 12.4942 15.7699 12.1374 16.0236 11.89L19.6736 8.33156C20.3127 7.70817 19.9602 6.62246 19.0766 6.49436L14.0323 5.76123C13.6815 5.71032 13.3782 5.49001 13.2213 5.17206L10.9656 0.600543C10.7682 0.20013 10.3841 -5.14809e-05 9.99999 9.93083e-09C9.61596 5.14875e-05 9.23191 0.200233 9.03439 0.600543Z"
      fill="#E0E0E0"
    />
    <path
      d="M6.77865 5.17206L9.03439 0.600543C9.23191 0.200233 9.61596 5.14875e-05 9.99999 9.93083e-09V16.5081C9.82779 16.5081 9.65559 16.5493 9.49872 16.6317L4.98723 19.0038C4.19707 19.419 3.27341 18.7481 3.42444 17.8681L4.28594 12.8434C4.34601 12.4942 4.23003 12.1374 3.97638 11.89L0.326389 8.33156C-0.312748 7.70859 0.0397701 6.62287 0.923798 6.49436L5.96763 5.76123C6.31849 5.71032 6.62178 5.49001 6.77865 5.17206Z"
      fill="currentColor"
    />
  </svg>
);

import * as React from 'react';
import { type SVGProps } from 'react';
export const IconHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    width="1em"
    height="1em"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.6736 15.0864L15.013 27.2767L3.35233 15.0864M3.35233 15.0864C2.58321 14.2964 1.97738 13.3469 1.57299 12.2976C1.16861 11.2483 0.974437 10.122 1.0027 8.98955C1.03095 7.85715 1.28104 6.74319 1.73719 5.71781C2.19335 4.69244 2.84569 3.77787 3.65315 3.03169C4.46061 2.28551 5.4057 1.72389 6.42889 1.3822C7.45208 1.0405 8.53121 0.926135 9.59833 1.04629C10.6655 1.16645 11.6975 1.51854 12.6293 2.08037C13.5612 2.64221 14.3728 3.40163 15.013 4.31082C15.6559 3.40823 16.4684 2.65545 17.3997 2.09957C18.3309 1.5437 19.3608 1.1967 20.4249 1.0803C21.4891 0.963892 22.5645 1.08059 23.5839 1.42308C24.6034 1.76557 25.5449 2.32649 26.3495 3.07072C27.1541 3.81496 27.8045 4.72649 28.2601 5.74827C28.7156 6.77004 28.9664 7.88007 28.9969 9.00888C29.0273 10.1377 28.8366 11.261 28.4369 12.3084C28.0371 13.3559 27.4368 14.305 26.6736 15.0963"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

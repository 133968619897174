import { useState } from 'react';
import { useRouter } from 'next/router';

import { type AxiosError, isAxiosError } from 'axios';

import {
  getRefreshToken,
  getToken,
  setRefreshToken,
  setToken as setServerToken,
} from '@/utils/authentication/authHelpers';
import { authRoutes, pageRoutes } from '@/utils/constants/pageRoutes';

import { refreshAuthTokens } from '@/services/auth/refreshAuthTokens';

export const useAuthToken = () => {
  const router = useRouter();

  const [isLoading, setLoading] = useState(true);
  const [token, setToken] = useState<null | string>(null);
  const region = (router?.query?.region as string) || 'nl';

  const pathName = router.pathname.split('/').length < 3 ? '/' : `/${router?.pathname?.split('/')?.[3]}`;

  const fetchToken = async (logout: () => Promise<void>) => {
    if (!region) return;
    if (pathName === pageRoutes.impersonate) return setLoading(false);

    const fetchedToken = await getToken();

    if (!fetchedToken) {
      const refreshToken = await getRefreshToken();

      if (!refreshToken) {
        if (authRoutes.includes(pathName)) return setLoading(false);
        logout();
        setLoading(false);
        return;
      }

      try {
        const { token, refresh_token } = await refreshAuthTokens(refreshToken, region as string);

        if (!token || !refreshToken) return;

        setToken(token);
        setServerToken(token);
        setRefreshToken(refresh_token);

        setLoading(false);
        return;
      } catch (error: unknown) {
        if (error instanceof Error && isAxiosError(error)) {
          const axiosError = error as AxiosError;

          if (axiosError?.response?.status === 401) logout();
        }
        setLoading(false);
      }
    }

    setToken(fetchedToken);

    setLoading(false);
    return;
  };

  return { token, setToken, isLoading, fetchToken };
};

import { cn } from './utils/cn';

interface ISkeleton {
  className: string;
}

export const Skeleton = ({ className }: ISkeleton) => (
  <span
    data-component="genericSkeleton"
    className={cn('inline-block rounded-md', 'animate-pulse bg-gray-100', className)}
  />
);

import { cloneElement, isValidElement, type ReactNode, useRef, useState } from 'react';

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';

interface ITooltipProps {
  content: ReactNode;
  children: ReactNode;
  passedOpen?: boolean;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'default' | 'white';
}

const sizeClasses = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
};

export const Tooltip = ({ content, children, passedOpen = false, size = 'md', variant = 'default' }: ITooltipProps) => {
  const [isOpen, setIsOpen] = useState(passedOpen);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    middleware: [variant === 'default' ? offset(20) : offset(25), flip(), shift(), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <>
      {isValidElement(children) &&
        cloneElement(
          children,
          getReferenceProps({
            ref: refs.setReference,
            ...children.props,
            ...getReferenceProps(),
          }),
        )}

      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="z-10 drop-shadow-xl"
          data-component="tooltip"
        >
          {variant === 'default' ? (
            <div className="w-full rounded bg-gray-800 pt-[3px] text-white">
              <div className={`${sizeClasses[size]} px-2 py-1 text-sm font-normal`}>{content}</div>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={10}
                height={6}
                tipRadius={1}
                className="fill-gray-800 drop-shadow-xl"
              />
            </div>
          ) : (
            <div className="bg-linear-gradient-x pt-[3px]">
              <div
                className={`${sizeClasses[size]} overflow-hidden bg-white p-5 px-2 py-1 text-start text-sm font-normal`}
              >
                <p>{content}</p>
              </div>
              {size === 'md' ? (
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  width={25}
                  height={17}
                  tipRadius={1}
                  fill="white"
                  className="drop-shadow-xl"
                />
              ) : (
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  width={10}
                  height={6}
                  tipRadius={1}
                  fill="white"
                  className="drop-shadow-xl"
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

Tooltip.displayName = 'Tooltip';

import { forwardRef, type Ref, useEffect, useRef, useState } from 'react';
import { DayPicker, useDayPicker } from 'react-day-picker';
import router from 'next/router';
import { useTranslation } from 'next-i18next';

import { IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import { addMonths, format, setMonth } from 'date-fns';
import { enGB, fr, nl, nlBE } from 'date-fns/locale';

import { IconCalendar } from './icons/IconCalendar';
import { cn } from './utils/cn';
import { Button } from './Button';
import { Popover } from './Popover';

interface IDatePickerInputProps {
  value?: Date;
  label?: string;
  className?: string;
  classNames?: string;
  disabledFrom?: Date;
  disabledUntil?: Date;
  placeholder?: string;
  icon?: React.ReactNode;
  locale?: string;
  error?: string;
  onChange?: (date: Date | undefined) => void;
}

interface IDay {
  date: Date;
}

export const DatePickerInput = forwardRef<HTMLInputElement, IDatePickerInputProps>(
  (props: IDatePickerInputProps, ref: Ref<HTMLInputElement>) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<Date | undefined>(props.value);
    const today = new Date();
    const [newMonth, setNewMonth] = useState(selected || today);
    const [dropdownOpened, setDropdownOpened] = useState({
      month: false,
      year: false,
    });
    const locales = { fr, enGB, nl, nlBE };
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const popoverRef = useRef<{ closePopover: () => void } | null>(null);
    const renderLocale = () => {
      switch (router?.query?.lang) {
        case 'fr-FR':
          return locales.fr;
        case 'nl-BE':
          return locales.nlBE;
        case 'nl-NL':
          return locales.nl;
        case 'en-GB':
          return locales.enGB;
        default:
          return locales.enGB;
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node))
        setDropdownOpened({ month: false, year: false });
    };

    useEffect(() => {
      if (dropdownOpened.month || dropdownOpened.year) document.addEventListener('mousedown', handleClickOutside);
      else document.removeEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownOpened]);

    const handleDayClick = (date: Date | undefined) => {
      if (!date) return;
      handleClosePopover();
      setNewMonth(date);
      setSelected(date);
      props.onChange?.(date);
    };

    const isDateSelected = (day: IDay) => {
      if (!selected || !day) return false;
      // Create new Date objects to avoid mutating the original ones
      const d1 = new Date(day?.date);
      const d2 = new Date(selected);

      // Set the time components (hours, minutes, seconds, milliseconds) to zero
      d1.setHours(0, 0, 0, 0);
      d2.setHours(0, 0, 0, 0);

      // Compare the normalized dates
      return d1.getTime() === d2.getTime();
    };

    const handleClosePopover = () => {
      if (popoverRef.current) popoverRef.current.closePopover();
    };

    const isDateToday = (day: IDay) => {
      if (!day) return false;
      const d1 = new Date(day?.date);
      const d2 = new Date(today);
      d1.setHours(0, 0, 0, 0);
      d2.setHours(0, 0, 0, 0);
      return d1.getTime() === d2.getTime();
    };

    return (
      <Popover
        style="minimal"
        className="w-full"
        ref={popoverRef}
        content={
          <div>
            <DayPicker
              mode="single"
              selected={selected}
              onSelect={handleDayClick}
              locale={renderLocale()}
              fixedWeeks
              endMonth={addMonths(today, 13)}
              captionLayout="dropdown"
              onMonthChange={(newMonth) => setNewMonth(newMonth)}
              showOutsideDays
              // If a disabledFrom is provided, disable all dates before it, if a toDate is provided, disable all dates after it
              disabled={
                props.disabledFrom
                  ? { before: props.disabledFrom }
                  : props.disabledUntil
                    ? { after: props.disabledUntil }
                    : undefined
              }
              className={props.className}
              classNames={{
                month_grid: 'mt-3 max-w-[400px] border-t border-gray-100',
                month_caption: 'text-sm font-bold',
                dropdowns: 'flex items-center w-[190px] gap-1 left-0 justify-center h-8',
                caption_label: 'text-sm font-medium flex items-center justify-center w-[190px]',
                chevron: 'w-4 h-4 z-[50]',
                nav: 'absolute w-[190px] justify-between flex h-8 items-center',
                nav_button: 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                day: 'h-8 w-8 p-0 aria-selected:opacity-100 font-["Gotham"] font-normal text-sm',
                weekdays: 'pt-12',
                weekday: 'text-gray-300 text-sm pt-4 pb-2',
                day_today: 'bg-accent text-accent-foreground',
                day_outside:
                  'day-outside text-gray-800  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
                day_disabled: 'text-gray-800',
                day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
                day_hidden: 'invisible',
              }}
              components={{
                DayButton: (props) => {
                  const { day, ...buttonProps } = props;
                  const { goToMonth } = useDayPicker();
                  return (
                    <button
                      type="button"
                      {...buttonProps}
                      className={clsx(
                        'm-1 size-8 text-black transition-all duration-100 ease-in-out',
                        'hover:rounded md:hover:bg-gradient-calendar md:hover:text-white',
                        isDateSelected(day) && 'rounded bg-gradient-calendar font-gotham text-sm text-white',
                        isDateToday(day) && 'border border-transparent border-b-primary',
                        'translate-y-0 active:translate-y-0.5',
                      )}
                      onClick={() => {
                        goToMonth(day.date);
                        handleDayClick(day.date);
                      }}
                    />
                  );
                },
                Dropdown: (props) => {
                  const { goToMonth, months } = useDayPicker();

                  const today = new Date();

                  if (props.className === 'rdp-months_dropdown') {
                    const selectedYear = newMonth.getFullYear();

                    const selectItems = Array.from({ length: 12 }, (_, i) => {
                      const monthDate = setMonth(new Date(selectedYear, i), i);

                      return {
                        value: i.toString(),
                        label: format(monthDate, 'MMMM', { locale: renderLocale() }),
                      };
                    });

                    return (
                      <>
                        <div className="relative">
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              setDropdownOpened({
                                ...dropdownOpened,
                                month: !dropdownOpened.month,
                                year: false,
                              })
                            }
                          >
                            {format(newMonth, 'MMMM', { locale: renderLocale() })}
                          </span>
                          {dropdownOpened.month && (
                            <div
                              ref={dropdownRef}
                              className={'absolute z-[70] rounded-md border border-gray-50 bg-white shadow-dropdown'}
                            >
                              {selectItems.map((item) => (
                                <div
                                  key={item.value}
                                  onClick={() => {
                                    const newDate = new Date(selectedYear, parseInt(item.value));
                                    goToMonth(newDate);
                                    setDropdownOpened({ month: false, year: false });
                                  }}
                                  className="z-[70] cursor-pointer border-b border-gray-50 bg-white px-4 py-1 font-normal text-black transition duration-200 ease-out hover:bg-gray-50"
                                >
                                  {item.label}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <Button
                          variant="secondary"
                          type="button"
                          className="absolute right-0 top-0 z-10 h-8 px-3 text-xs"
                          onClick={() => {
                            goToMonth(today);
                            handleDayClick(today);
                          }}
                        >
                          {t('calendar.today')}
                        </Button>
                      </>
                    );
                  } else if (props.className === 'rdp-years_dropdown') {
                    const earliestYear = new Date().getFullYear();
                    const latestYear = new Date().getFullYear() + 1;

                    if (earliestYear && latestYear) {
                      const years = Array.from({ length: latestYear - earliestYear + 1 }, (_, i) => earliestYear + i);
                      return (
                        <div className="relative">
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              setDropdownOpened({ ...dropdownOpened, year: !dropdownOpened.year, month: false })
                            }
                          >
                            {format(newMonth, 'yyyy', { locale: renderLocale() })}
                          </span>
                          {dropdownOpened.year && (
                            <div
                              ref={dropdownRef}
                              className="absolute z-[70] rounded-md border border-gray-50 bg-white shadow-dropdown"
                            >
                              {years.map((year) => (
                                <div
                                  key={year}
                                  onClick={() => {
                                    const newDate = months?.[0].date || new Date();
                                    newDate.setFullYear(year);
                                    goToMonth(newDate);
                                    setDropdownOpened({ month: false, year: false });
                                  }}
                                  className={
                                    'z-[70] cursor-pointer border-b border-gray-50 px-4 py-1 font-normal text-black transition duration-200 ease-out hover:bg-gray-50'
                                  }
                                >
                                  {year}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      );
                    }
                  }
                  return <></>;
                },
              }}
            />
          </div>
        }
        placement="bottom-start"
      >
        <div ref={ref} data-component="datePickerInput" className={cn('relative', props.className)}>
          {props.label && <label className="mb-2 flex items-center gap-1 text-xs font-medium">{props.label}</label>}
          <Button
            variant="outline"
            type="button"
            className={cn('flex h-10 w-full justify-start items-center px-3 text-black', {
              'border-red-500 text-red-500': props.error,
            })}
            onClick={() => setNewMonth(today)}
          >
            <IconCalendar />
            <span className="text-nowrap text-sm text-black">
              {selected ? selected.toLocaleDateString() : props?.placeholder}
            </span>
            {selected !== undefined && (
              <div>
                <span
                  className="cursor-pointer text-sm text-gray-800"
                  onClick={() => {
                    setSelected(undefined);
                    props.onChange?.(undefined);
                  }}
                >
                  <IconX size={15} />
                </span>
              </div>
            )}
          </Button>
          {props.error && <small className="text-xs text-red-500">{props.error}</small>}
        </div>
      </Popover>
    );
  },
);

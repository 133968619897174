import { cn } from './utils/cn';

interface IAvatarIndicator {
  indicatorCount?: number;
  className?: string;
}

export const AvatarIndicator = ({ indicatorCount, className }: IAvatarIndicator) => (
  <div
    className={cn(
      'inline-flex h-5 min-w-5 flex-col items-center justify-center gap-2.5 rounded border-2 border-solid border-white bg-blue-950 px-1.5',
      className,
    )}
    data-component="AvatarIndicator"
  >
    <div className="font-['Gotham'] text-[10px] font-bold leading-[10px] text-white">{`+${indicatorCount}`}</div>
  </div>
);

import * as React from 'react';
import { type SVGProps } from 'react';
export const IconProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 15C1 16.8385 1.36212 18.659 2.06569 20.3576C2.76925 22.0561 3.80048 23.5995 5.1005 24.8995C6.40053 26.1995 7.94387 27.2307 9.64243 27.9343C11.341 28.6379 13.1615 29 15 29C16.8385 29 18.659 28.6379 20.3576 27.9343C22.0561 27.2307 23.5995 26.1995 24.8995 24.8995C26.1995 23.5995 27.2307 22.0561 27.9343 20.3576C28.6379 18.659 29 16.8385 29 15C29 13.1615 28.6379 11.341 27.9343 9.64243C27.2307 7.94387 26.1995 6.40053 24.8995 5.10051C23.5995 3.80048 22.0561 2.76925 20.3576 2.06569C18.659 1.36212 16.8385 1 15 1C13.1615 1 11.341 1.36212 9.64243 2.06569C7.94387 2.76925 6.40053 3.80048 5.1005 5.10051C3.80048 6.40053 2.76925 7.94387 2.06569 9.64243C1.36212 11.341 1 13.1615 1 15Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3333 11.8889C10.3333 13.1266 10.825 14.3136 11.7001 15.1887C12.5753 16.0639 13.7623 16.5556 15 16.5556C16.2377 16.5556 17.4246 16.0639 18.2998 15.1887C19.175 14.3136 19.6666 13.1266 19.6666 11.8889C19.6666 10.6512 19.175 9.46423 18.2998 8.58906C17.4246 7.71389 16.2377 7.22222 15 7.22222C13.7623 7.22222 12.5753 7.71389 11.7001 8.58906C10.825 9.46423 10.3333 10.6512 10.3333 11.8889Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.92798 25.654C6.31299 24.3726 7.10082 23.2494 8.17458 22.4511C9.24835 21.6528 10.5509 21.2218 11.8889 21.2222H18.1111C19.4508 21.2218 20.7549 21.6537 21.8295 22.4539C22.904 23.2541 23.6916 24.3797 24.0751 25.6633"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { cn } from './utils/cn';

interface LabelProps {
  children: React.ReactNode | string | React.ReactNode[];
  htmlFor?: string;
  className?: string;
}

export const Label = ({ children, className, ...props }: LabelProps) => (
  <label
    data-component="label"
    className={cn('flex items-center gap-1 text-xs font-medium text-black', className)}
    {...props}
  >
    {children}
  </label>
);

import * as React from 'react';

import { cn } from './utils/cn';

interface IIsland extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  noShadow?: boolean;
  noPadding?: boolean;
}

export const Island = ({ children, className, noShadow = false, noPadding = false, ...props }: IIsland) => (
  <div
    data-component="island"
    className={cn(
      'relative bg-white px-5 py-5 shadow-[0_-0.5rem_2rem_rgba(38,41,92,0.04)] md:px-10 md:py-9',
      {
        'drop-shadow-none': noShadow,
        'md:px-0 md:py-0': noPadding,
      },
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

Island.displayName = 'Island';

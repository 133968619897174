import axios, { type AxiosResponse } from 'axios';

// Define a constant for the refresh token key
export const refreshTokenKey = 'YO_DKHCQ8';

// Type for response data
interface TokenResponse {
  token: string | null;
}

interface RefreshTokenResponse {
  refreshToken: string;
}

// Function to get the authentication token
export const getToken = async (): Promise<string | null> => {
  try {
    const response: AxiosResponse<TokenResponse> = await axios.get('/api/auth/get-token', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data.token;
  } catch (error) {
    console.error('Failed to get token:', error);
    return null;
  }
};

// Function to set the authentication token
export const setToken = async (newToken: string | null): Promise<void> => {
  try {
    await axios.post(
      '/api/auth/set-token',
      { token: newToken },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    console.error('Failed to set token:', error);
  }
};

// Function to get the refresh token
export const getRefreshToken = async (): Promise<string | null> => {
  try {
    const response: AxiosResponse<RefreshTokenResponse> = await axios.get('/api/auth/get-refresh-token', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data.refreshToken || null;
  } catch (error) {
    console.error('Failed to get refresh token:', error);
    return null;
  }
};

// Function to set the refresh token
export const setRefreshToken = async (refreshToken: string): Promise<void> => {
  try {
    await axios.post(
      '/api/auth/set-refresh-token',
      { token: refreshToken },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    console.error('Failed to set refresh token:', error);
  }
};

// Function to remove authentication tokens
export const removeAuthTokens = async (): Promise<void> => {
  try {
    await axios.post(
      '/api/auth/remove-tokens',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    console.error('Failed to remove tokens:', error);
  }
};

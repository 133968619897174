import { cn } from './utils/cn';

interface IGenericSkeleton {
  className: string;
}

/**
 * @deprecated Can be removed and replaced by Skeleton
 */
export const GenericSkeleton = ({ className }: IGenericSkeleton) => (
  <span
    data-component="genericSkeleton"
    className={cn('inline-block h-full w-full rounded-md', 'animate-pulse bg-gray-100', className)}
  />
);

// eslint-disable-next-line @typescript-eslint/no-require-imports
const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: 'nl-NL',
    locales: ['nl-NL', 'en-GB', 'fr-FR', 'nl-BE'],
  },
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  trailingSlash: false,
  localePath: path.resolve('./public/locales'),
};

import { IconArrowNarrowLeft } from '@tabler/icons-react';

import { LinkText } from './LinkText';

interface IBreadCrumb {
  pathName: string;
  query?: string;
  text: string;
}

export const BreadCrumb = ({ pathName, query, text }: IBreadCrumb) => (
  <LinkText data-component="BreadCrumb" pathName={pathName} query={query} classNames="flex items-center">
    <>
      <IconArrowNarrowLeft size={20} />
      {text}
    </>
  </LinkText>
);

BreadCrumb.displayName = 'BreadCrumb';

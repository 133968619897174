import { useEffect, useRef, useState } from 'react';
import { DayPicker, useDayPicker } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import router from 'next/router';

import clsx from 'clsx';
import { addMonths, format, setMonth } from 'date-fns';
import { enGB, fr, nl, nlBE } from 'date-fns/locale';

import 'react-day-picker/dist/style.css';

import { Button } from './Button';

interface ICalendarProps {
  selectedDates?: Date[];
  onSelect?: (dates: Date[]) => void;
}

interface IDay {
  date: Date;
}

export const onHoverCalendarClass = clsx(
  'before:absolute before:inset-0 before:z-[-1] before:rounded before:bg-gradient-calendar before:opacity-0 before:transition-all before:duration-150 before:ease-in before:content-[""]',
  'z-[1] flex size-full items-center justify-center lg:hover:text-white',
  'lg:hover:before:opacity-100',
);

export const Calendar = ({ selectedDates, onSelect, ...props }: ICalendarProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Date[]>(selectedDates || []);
  const today = new Date();
  const [newMonth, setNewMonth] = useState(today);
  const [dropdownOpened, setDropdownOpened] = useState({
    month: false,
    year: false,
  });

  const locales = { fr, enGB, nl, nlBE };
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const renderLocale = () => {
    switch (router?.query?.lang) {
      case 'fr-FR':
        return locales.fr;
      case 'nl-BE':
        return locales.nlBE;
      case 'nl-NL':
        return locales.nl;
      case 'en-GB':
        return locales.enGB;
      default:
        return locales.enGB;
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node))
      setDropdownOpened({ month: false, year: false });
  };

  useEffect(() => {
    if (dropdownOpened.month || dropdownOpened.year) document.addEventListener('mousedown', handleClickOutside);
    else document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpened]);

  const isDateSelected = (day: IDay) => {
    const dayDate = new Date(day.date);
    return selected.some((selected) => selected.getTime() === dayDate.getTime());
  };

  const isDateToday = (day: IDay) => {
    if (!day) return false;
    const d1 = new Date(day?.date);
    const d2 = new Date(today);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    return d1.getTime() === d2.getTime();
  };

  const handleSelect = (dates: Date[] | undefined) => {
    if (dates) {
      setSelected(dates);
      if (onSelect) onSelect(dates);
    }
  };

  const handleDates = (day: IDay, dates: Date[]) => {
    if (isDateSelected(day)) {
      handleSelect(dates.filter((date) => date.getTime() !== day.date.getTime()));
      return;
    }
    handleSelect([...dates, day.date]);
  };

  return (
    <div className="relative" data-component="calendar">
      <div className="absolute right-0 top-0 z-10 flex items-center gap-2">
        <Button variant="secondary" type="button" className="h-8 px-3 text-xs" onClick={() => setNewMonth(today)}>
          {t('calendar.today')}
        </Button>
        <span
          className="cursor-pointer text-xs text-gray-500 transition-colors duration-200 ease-in-out hover:text-black"
          onClick={() => handleSelect([])}
        >
          {t('calendar.clear')}
        </span>
      </div>
      <DayPicker
        {...props}
        selected={selected}
        onSelect={handleSelect}
        month={newMonth}
        onMonthChange={(newMonth) => setNewMonth(newMonth)}
        disabled={{ before: new Date(), after: addMonths(today, 12) }}
        mode="multiple"
        locale={renderLocale()}
        showOutsideDays
        captionLayout="dropdown"
        fixedWeeks
        endMonth={addMonths(today, 13)}
        components={{
          DayButton: (props) => {
            const { day, ...buttonProps } = props;
            return (
              <button
                {...buttonProps}
                type="button"
                className={clsx(
                  'm-2 size-8 text-black transition-all duration-100 ease-in-out md:m-2',
                  'hover:rounded md:hover:bg-gradient-calendar md:hover:text-white',
                  isDateSelected(day) && 'rounded bg-gradient-calendar font-["Gotham"] text-sm font-normal text-white',
                  isDateToday(day) && 'border border-transparent border-b-primary',
                  'translate-y-0 active:translate-y-0.5',
                )}
                onClick={() => {
                  handleDates(day, selected);
                }}
              />
            );
          },
          Dropdown: (props) => {
            const { goToMonth, months } = useDayPicker();

            if (props.className === 'rdp-months_dropdown') {
              const selectedYear = newMonth.getFullYear();

              const selectItems = Array.from({ length: 12 }, (_, i) => {
                const monthDate = setMonth(new Date(selectedYear, i), i);

                return {
                  value: i.toString(),
                  label: format(monthDate, 'MMMM', { locale: renderLocale() }),
                };
              });

              return (
                <>
                  <div className="relative">
                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        setDropdownOpened({
                          ...dropdownOpened,
                          month: !dropdownOpened.month,
                          year: false,
                        })
                      }
                    >
                      {format(newMonth, 'MMMM', { locale: renderLocale() })}
                    </span>
                    {dropdownOpened.month && (
                      <div
                        ref={dropdownRef}
                        className={'absolute z-[70] rounded-md border border-gray-50 bg-white shadow-dropdown'}
                      >
                        {selectItems.map((item) => (
                          <div
                            key={item.value}
                            onClick={() => {
                              const newDate = new Date(selectedYear, parseInt(item.value));
                              goToMonth(newDate);
                              setDropdownOpened({ month: false, year: false });
                            }}
                            className="z-[70] cursor-pointer border-b border-gray-50 bg-white px-4 py-1 font-normal text-black transition duration-200 ease-out hover:bg-gray-50"
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            } else if (props.className === 'rdp-years_dropdown') {
              const earliestYear = new Date().getFullYear();
              const latestYear = new Date().getFullYear() + 1;

              if (earliestYear && latestYear) {
                const years = Array.from({ length: latestYear - earliestYear + 1 }, (_, i) => earliestYear + i);
                return (
                  <div className="relative">
                    <span
                      className="cursor-pointer"
                      onClick={() => setDropdownOpened({ ...dropdownOpened, year: !dropdownOpened.year, month: false })}
                    >
                      {format(newMonth, 'yyyy', { locale: renderLocale() })}
                    </span>
                    {dropdownOpened.year && (
                      <div
                        ref={dropdownRef}
                        className="absolute z-[70] rounded-md border border-gray-50 bg-white shadow-dropdown"
                      >
                        {years.map((year) => (
                          <div
                            key={year}
                            onClick={() => {
                              const newDate = months?.[0].date || new Date();
                              newDate.setFullYear(year);
                              goToMonth(newDate);
                              setDropdownOpened({ month: false, year: false });
                            }}
                            className={
                              'z-[70] cursor-pointer border-b border-gray-50 px-4 py-1 font-normal text-black transition duration-200 ease-out hover:bg-gray-50'
                            }
                          >
                            {year}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }
            }
            return <></>;
          },
        }}
        classNames={{
          month_grid: 'mt-3 max-w-[400px] border-t border-gray-100',
          month_caption: 'text-sm font-bold',
          dropdowns: 'flex items-center w-[190px] gap-1 left-0 justify-center h-8',
          caption_label: 'text-sm font-medium flex items-center justify-center w-[190px]',
          chevron: 'w-4 h-4 z-[50]',
          nav: 'absolute w-[190px] justify-between flex h-8 items-center',
          nav_button: 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          nav_button_previous: 'absolute left-1',
          nav_button_next: 'absolute right-1',
          day: 'h-8 w-8 p-0 aria-selected:opacity-100 font-["Gotham"] font-normal text-sm',
          weekdays: 'pt-12',
          weekday: 'text-gray-300 text-sm pt-4 pb-2',
          day_today: 'bg-accent text-accent-foreground',
          day_outside:
            'day-outside text-gray-800  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
          day_disabled: 'text-gray-800',
          day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
          day_hidden: 'invisible',
        }}
      />
    </div>
  );
};

Calendar.displayName = 'Calendar';

import { type IUser } from '@/types';

interface ICustomWindow extends Window {
  dataLayer?: Record<string, unknown>[];
}

export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const Pageview = (url: URL, user: IUser | null) => {
  if (typeof window !== 'undefined' && GOOGLE_TAG_MANAGER_ID) {
    const _window = window as ICustomWindow;
    const dataLayer = _window.dataLayer;

    dataLayer &&
      dataLayer.push({
        event: 'pageview',
        page: url,
        user_id: user?.clientId ?? 'unknown',
      });
  }
};

export const sendDefaultConsentGTM = () => {
  if (typeof window !== 'undefined' && GOOGLE_TAG_MANAGER_ID) {
    // eslint-disable-next-line no-undef
    gtag('consent', 'default', {
      ad_personalization: 'denied',
      ad_user_data: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied',
    });
  }
};

export const sendUpdateConsentGTM = (isMarketingCookiesAllowed: boolean, isStatisticsCookiesAllowed: boolean) => {
  if (typeof window !== 'undefined' && GOOGLE_TAG_MANAGER_ID) {
    // eslint-disable-next-line no-undef
    gtag('consent', 'update', {
      ad_personalization: isMarketingCookiesAllowed ? 'granted' : 'denied',
      ad_user_data: isMarketingCookiesAllowed ? 'granted' : 'denied',
      ad_storage: isMarketingCookiesAllowed ? 'granted' : 'denied',
      analytics_storage: isStatisticsCookiesAllowed ? 'granted' : 'denied',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
    });
  }
};

import axios, { type AxiosResponse } from 'axios';

import apiRoutes from '@/utils/constants/apiRoutes';
import { getRegions } from '@/utils/regions/regionsConfig';

interface IRefreshTokenResponse {
  token: string;
  refresh_token: string;
}

export const refreshAuthTokens = async (refreshToken: string, region: string) => {
  const regions = getRegions();
  const currentRegion = regions.find((r) => r.slug === region);

  const response = await axios.post<{ refresh_token: string }, AxiosResponse<IRefreshTokenResponse>>(
    `${currentRegion?.apiUrl}${apiRoutes.refreshToken}`,
    {
      refresh_token: refreshToken,
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

  return {
    token: response.data.token,
    refresh_token: response.data.refresh_token,
  };
};

import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// eslint-disable-next-line import/no-unresolved
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

import { AuthProvider } from '@/components/auth/AuthProvider';
import { MantineProvider } from '@/components/common/MantineProvider';
import { ToastProvider } from '@/components/ui/toast/ToastProvider';
import { getMarketingCookieConsent } from '@/utils/cookies/cookieConsentRepository';

import '../styles/globals.css';

import { queryClient } from '../services/queryClient';

import nextI18nextConfig from '@/next-i18next.config';

const hotjarEnabled = process.env.NEXT_PUBLIC_HOTJAR_ENABLED === 'true';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import GoogleAnalytics from '@/components/analytics/GoogleAnalytics';
import EppoRandomizationProvider from '@/components/eppo/EppoRandomizationProvider';
import { MaintenanceProvider } from '@/components/maintenance/MaintenanceProvider';

declare global {
  interface Window {
    userGuiding: {
      identify: (id: string, data: { name: string; email: string }) => void;
    };
    hj: unknown;
    gtag: unknown;
  }
}

declare global {
  interface Navigator {
    userAgentData: {
      brands: { brand: string; version: string }[];
      platform: string;
      mobile: boolean;
    };
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const isMarketingCookiesAllowed = getMarketingCookieConsent() === '1';
  const router = useRouter();

  useEffect(() => storePathValues, [router.asPath]);

  const storePathValues = () => {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath || '');
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', globalThis.location.pathname);
  };

  return (
    <>
      <Head>
        <title>YoungOnes</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Head>

      <span id="CollectedForms-14493935" />
      <ToastProvider autoDelete>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <MantineProvider>
              <GoogleAnalytics />
              {isMarketingCookiesAllowed && process.env.NODE_ENV !== 'development' && (
                <Script
                  id="hs-script-loader"
                  strategy="lazyOnload"
                  type="text/javascript"
                  async
                  defer
                  src="//js.hs-scripts.com/14493935.js"
                />
              )}
              {isMarketingCookiesAllowed && hotjarEnabled && (
                <Script id="hotjar">
                  {`(function(h,o,t,j,a,r){
                      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                      h._hjSettings={hjid:4966637,hjsv:6};
                      a=o.getElementsByTagName('head')[0];
                      r=o.createElement('script');r.async=1;
                      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                      a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
                </Script>
              )}
              <EppoRandomizationProvider>
                <MaintenanceProvider>
                  <Component {...pageProps}></Component>
                </MaintenanceProvider>
              </EppoRandomizationProvider>
              <SpeedInsights />
              <Analytics />
            </MantineProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AuthProvider>
      </ToastProvider>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18nextConfig);

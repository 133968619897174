import detectPlatform from '../lib/detectPlatform';

export interface IEventData {
  region: string;
  userId?: string;
  clientId?: string;
  eventName: string;
  eventContext: string;
  meta?: Record<string, string>;
}

export interface ITrackEvent extends IEventData {
  type: string;
  timestamp: string;
  osType: string;
  browserType: string;
  browserVersion: string;
}

export const trackEvent = (type: string, eventData: IEventData, sendBatch: boolean = false) => {
  const platform = detectPlatform();

  const event: ITrackEvent = {
    ...eventData,
    type,
    timestamp: new Date().toISOString(),
    osType: platform?.os || 'unknown',
    browserType: platform?.browser || 'unknown',
    browserVersion: platform?.version || 'unknown',
  };

  navigator.sendBeacon('/api/event', JSON.stringify({ event, sendBatch }));
};
